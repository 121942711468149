import { apiProvider } from '../../../../../../libs/api-provider';
import Component from '../../../../../../libs/components/component';
import { formToJSON } from '../../../../../../libs/form-to-json';
import { register } from '../../../../../../libs/register';
import { openLoader, closeLoader } from '../../../templates/mt11-loader/script';
import { format, parse } from 'date-fns';

export default class ConfirmMailGcc extends Component {
    constructor(name, root) {
        super(name, root);

        this.form = this._dEl('form');
        this.submit = this._dEl('submit');
        this.initialSection = this._dEl('previousToConfirmation');

        this.successSection = this._dEl('afterToConfirmationSuccess');
        this.errorSection = this._dEl('afterToConfirmationError');

        this.genericErrorText = this._dEl('genericErrorText');
        this.birthdateErrorText = this._dEl('birthdateErrorText');
        this.expiredLinkErrorText = this._dEl('expiredLinkErrorText');
        this.noCodeProvidedError = this._dEl('noCodeProvidedError');
        this._addEventListeners();
        this._init();
    }

    _addEventListeners() {
        this.form.addEventListener('submit', (event) => {
            event.preventDefault();
            event.stopPropagation();
        });

        this.form.addEventListener('ecInputChanged', (event) => {
            event.preventDefault();
            !event.data.valid
                ? this._disableSubmit()
                : this._getFormValidity()
                ? this._enableSubmit()
                : this._disableSubmit();
            event.stopPropagation();
        });

        this.submit.addEventListener('click', async (event) => {
            event.preventDefault();
            if (!this._getFormValidity()) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }

            await this._submitForm();
        });
    }

    _init() {
        const urlParams = new URLSearchParams(window.location.search);
        let confirmationCode = urlParams.get('gccEmailVerificationCode');
        if (!confirmationCode) {
            this._showErrorSection('NO_CODE');
            return;
        }

        this.initialSection.classList.remove('opacity-0');
    }

    _getFormValidity() {
        if (!this.form.checkValidity()) {
            return false;
        }
        return this._validDates(this.form);
    }

    _validDates(form) {
        if (!form) return false;
        const validities = Array.from(form.querySelectorAll('.mt23-datepicker')).map((el) => {
            const elObj = register.getClass(el);
            return {
                name: elObj.getName(),
                value: elObj.getValue(),
                valid: elObj.isValid(),
            };
        });
        const result = validities.filter((el) => !el.valid).length <= 0;
        return result;
    }

    async _submitForm() {
        openLoader('main');
        try {
            const urlParams = new URLSearchParams(window.location.search);
            let confirmationCode = urlParams.get('gccEmailVerificationCode');
            if (!confirmationCode) {
                console.error('Confirmation code not present, return');
                return;
            }

            const birthdate = formToJSON(this.form).dataNascita;
            const date = new Date(parse(birthdate, 'dd/MM/yyyy', new Date()));
            await apiProvider.confirmEmailAddressGCC(confirmationCode, format(date, 'yyyyMMdd'));
            this._showSuccessSection();
        } catch (error) {
            console.error(error);
            this._showErrorSection(error);
        } finally {
            closeLoader('main');
        }
    }

    _showSuccessSection() {
        this.initialSection.classList.add('hidden');
        this.successSection.classList.remove('hidden');
    }

    _showErrorSection(error) {
        this.initialSection.classList.add('hidden');
        this.errorSection.classList.remove('hidden');

        // no provided code error
        if (error == 'NO_CODE') {
            this.noCodeProvidedError.classList.remove('hidden');
            return;
        }

        // backend error
        try {
            if (error?.codice == 'IS_ERR:RICHIESTA_AGGIORNAMENTO_EMAIL_GCC_NON_VALIDA_DATA_DI_NASCITA_NON_CORRETTA') {
                this.birthdateErrorText.classList.remove('hidden');
                return;
            }
            if (error?.codice == 'IS_ERR:RICHIESTA_AGGIORNAMENTO_EMAIL_GCC_NON_VALIDA_CERTIFICAZIONE_MAIL_SCADUTA') {
                this.expiredLinkErrorText.classList.remove('hidden');
                return;
            }
        } catch (e) {
            console.warn(e);
        }

        this.genericErrorText.classList.remove('hidden');
    }

    _enableSubmit() {
        this.submit.removeAttribute('disabled');
        this.submit.classList.add('!text-slate-50', '!bg-[--color-primary3]');
    }

    _disableSubmit() {
        this.submit.setAttribute('disabled', '');
        this.submit.classList.remove('!text-slate-50', '!bg-[--color-primary3]');
    }
}
