import * as dNextTick from 'next-tick';
import { apiProvider } from './api-provider';

///////////////////////////////////////////////////
//////////// PROMISIFY EVENT
///////////////////////////////////////////////////
export const buildWaitForEvent = (eventName) => (node, func) =>
    new Promise((resolve, reject) => {
        // reject for invalid node
        if (!(node instanceof window.HTMLElement || node instanceof window.SVGElement)) {
            return reject(new Error('tail-end: an HTML or SVG element is required.'));
        }

        // create the event handler
        const handler = () => {
            // unbind the handler
            node.removeEventListener(eventName, handler);
            // resolve the (now clean) node
            return resolve(node);
        };

        // bind the handler
        node.addEventListener(eventName, handler);

        // if it exists, call the function passing in the node
        if (typeof func === 'function') {
            window.requestAnimationFrame(() => func(node));
        }
    });

///////////////////////////////////////////////////
//////////// PROMISIFY ANIMATIONEND - TRANSITIONEND
///////////////////////////////////////////////////
export const animationEnd = buildWaitForEvent('animationend');
export const transitionEnd = buildWaitForEvent('transitionend');

///////////////////////////////////////////////////
//////////// DELAY
///////////////////////////////////////////////////
export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const nextTick = () => {
    return new Promise((resolve) => dNextTick(resolve));
};

///////////////////////////////////////////////////
//////////// DEBOUNCE
///////////////////////////////////////////////////
export const debounce = (cb, delay = 1000) => {
    let timeoutId = null;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = window.setTimeout(() => {
            cb.apply(null, args);
        }, delay);
    };
};

///////////////////////////////////////////////////
//////////// NEXT PREV SIBLINGS
///////////////////////////////////////////////////
export const getNextSibling = function (elem, selector) {
    // Get the next sibling element
    let sibling = elem.nextElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches && sibling.matches(selector)) return sibling;
        sibling = sibling.nextElementSibling;
    }
};

export const getPrevSibling = function (elem, selector) {
    // Get the next sibling element
    let sibling = elem.previousElementSibling;

    // If there's no selector, return the first sibling
    if (!selector) return sibling;

    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches && sibling.matches(selector)) return sibling;
        sibling = sibling.previousElementSibling;
    }
};

export const getNextSiblingAll = function (elem, selector) {
    // Get the next sibling element
    let sibling = elem.nextElementSibling;
    let result = [];
    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches && sibling.matches(selector)) result.push(sibling);
        sibling = sibling.nextElementSibling;
    }
    return result;
};

export const getPrevSiblingAll = function (elem, selector) {
    // Get the next sibling element
    let sibling = elem.previousElementSibling;
    let result = [];
    // If the sibling matches our selector, use it
    // If not, jump to the next sibling and continue the loop
    while (sibling) {
        if (sibling.matches && sibling.matches(selector)) result.push(sibling);
        sibling = sibling.previousElementSibling;
    }
    return result;
};

///////////////////////////////////////////////////
//////////// FIRST AND LAST CHILD
///////////////////////////////////////////////////
export const getFirstChild = function (elem, selector) {
    // Get the first child element
    let nodes = elem.childNodes;
    if (nodes.length <= 0) return;

    // If there's no selector, return the first sibling
    if (!selector) return nodes[0];

    // If the child node matches our selector, use it
    // If not, jump to the next child and continue the loop
    for (let i = 0; i < nodes.length; i++) {
        if (nodes[i].matches && nodes[i].matches(selector)) return nodes[i];
    }
};

export const getLastChild = function (elem, selector) {
    // Get the last child element
    let nodes = elem.childNodes;
    if (nodes.length <= 0) return;

    // If there's no selector, return the last sibling
    if (!selector) return nodes[nodes.length - 1];

    // If the child node matches our selector, use it
    // If not, jump to the prev child and continue the loop
    for (let i = nodes.length - 1; i >= 0; i--) {
        if (nodes[i].matches && nodes[i].matches(selector)) return nodes[i];
    }
};

export const getClosestAll = function (elem, selector) {
    const closestOne = (el, arr) => {
        if (!el) return arr;

        const clo = el.closest(selector);
        if (!clo) return arr;

        return closestOne(clo.parentElement, [clo, ...arr]);
    };

    return closestOne(elem, []);
};

export const getNextFormFocusableElement = function (elem) {
    // Get current form, than get next input and then focus it
    const form = elem.closest('form');
    const elements = Array.from(form.elements)
        .filter((el) => !el.matches('[tabindex="-1"]'))
        .filter((el) => {
            return !(
                window.getComputedStyle(el).visibility === 'hidden' || window.getComputedStyle(el).display === 'none'
            );
        });
    const index = elements.indexOf(elem);
    return elements[index + 1];
};

export const isInViewport = function (el) {
    const rect = el.getBoundingClientRect();
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

///////////////////////////////////////////////////
//////////// CREATE QUERY PARAMETERS
///////////////////////////////////////////////////
export const buildQuery = (obj) =>
    Object.entries(obj)
        .map((pair) => pair.map(encodeURIComponent).join('='))
        .join('&');

///////////////////////////////////////////////////
//////////// HTML ELEMENTS
///////////////////////////////////////////////////
export const htmlToElement = (html) => {
    let template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
};

export const emptyElement = (element) => {
    while (element.firstChild) {
        element.removeChild(element.lastChild);
    }
};

///////////////////////////////////////////////////
//////////// OTHERS
///////////////////////////////////////////////////
export const isPositive = (value) => {
    if (value == true || value == 'true' || value == 'S' || value == 's' || value == '1') {
        return true;
    }
    return false;
};

///////////////////////////////////////////////////
//////////// CALLBACK - ECOMMERCE - INFORMATIVA
///////////////////////////////////////////////////
export const getCallback = (registrationType = '') => {
    const urlParams = new URLSearchParams(window.location.search);
    let cb = urlParams.get('cb');
    if (!cb) return location.protocol + '//' + location.host;

    if (cb.startsWith('/')) {
        cb = location.protocol + '//' + location.host + cb;
    }

    if (registrationType) {
        try {
            let url = new URL(cb);
            url.searchParams.append('regType', registrationType);
            cb = url.toString();
        } catch (error) {
            console.warn('Cannot append param to callback');
        }
    }
    return cb;
};

export const checkOriginEcommerce = () => {
    const cb = decodeURIComponent(getCallback());
    try {
        const cbURL = new URL(cb);
        const allowedReferrers = window.allowedReferrers;
        /* DEBUG ONLY */
        const force = false;
        if (
            force ||
            (allowedReferrers &&
                allowedReferrers.ecommerce &&
                Object.values(allowedReferrers).includes(cbURL.hostname) &&
                cbURL.hostname === allowedReferrers.ecommerce)
        ) {
            return true;
        }
    } catch (error) {
        console.info('Cannot check if origin is ecommerce, maybe callback is a relative path');
    }
    return false;
};

export const checkOriginViaggi = () => {
    const cb = decodeURIComponent(getCallback());
    try {
        const cbURL = new URL(cb);
        const allowedReferrers = window.allowedReferrers;
        /* DEBUG ONLY */
        const force = false;
        if (
            force ||
            (allowedReferrers &&
                allowedReferrers.viaggi &&
                Object.values(allowedReferrers).includes(cbURL.hostname) &&
                cbURL.hostname === allowedReferrers.viaggi)
        ) {
            return true;
        }
    } catch (error) {
        console.info('Cannot check if origin is travel, maybe callback is a relative path');
    }
    return false;
};

/**
 *
 * @returns true if referrer comes from current domain
 */
export const isReferrerCurrentDomain = () => {
    if (!document.referrer) return false;
    try {
        const referrer = new URL(document.referrer);
        return referrer.origin === location.origin;
    } catch (e) {
        console.info('Cannot check referrer');
    }
    return false;
};

///////////////////////////////////////////////////
//////////// DEEP MERGE
///////////////////////////////////////////////////
export const isObject = (item) => {
    return item && typeof item === 'object' && !Array.isArray(item);
};

export const mergeDeep = (target, ...sources) => {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key])
                    Object.assign(target, {
                        [key]: {},
                    });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, {
                    [key]: source[key],
                });
            }
        }
    }

    return mergeDeep(target, ...sources);
};

///////////////////////////////////////////////////
//////////// COOKIES
///////////////////////////////////////////////////
export const getCookieByName = (name) => {
    let mt = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    if (mt) return mt.pop();
    return '';
};

export const writeCookieWithName = (name, value, domain, expires = undefined) => {
    document.cookie = `${name}=${value};domain=${domain};path=/;${expires ? 'expires=' + expires : ''}`;
};

///////////////////////////////////////////////////
//////////// ASYNC REPLACE
///////////////////////////////////////////////////
/**
 * Replace async
 * @param {*} string
 * @param {*} regexp
 * @param {*} replacerFunction
 * @returns
 */
export const replaceAsync = async (string, regexp, replacerFunction) => {
    const replacements = await Promise.all(Array.from(string.matchAll(regexp), (match) => replacerFunction(...match)));
    let i = 0;
    return string.replace(regexp, () => replacements[i++]);
};

///////////////////////////////////////////////////
//////////// COPY TEXT
///////////////////////////////////////////////////
export async function copyText(text) {
    try {
        await navigator.clipboard.writeText(text);
        console.log('Content ' + text + ' copied to clipboard');
    } catch (err) {
        console.error('Failed to copy: ', err);
    }
}

///////////////////////////////////////////////////
//////////// FORM CHECK WITHOUT TRIGGER VALIDATION
///////////////////////////////////////////////////
export function checkFormWithoutValidation(form) {
    const inputsInvalid = form.querySelector('input:invalid');
    if (inputsInvalid) {
        return false;
    } else {
        return true;
    }
}

///////////////////////////////////////////////////
//////////// FORM FIELD CHECK WITHOUT TRIGGER VALIDATION
///////////////////////////////////////////////////
export function checkFormFieldWithoutValidation(form, formFieldName) {
    const inputsInvalid = form.querySelectorAll('input:invalid');
    const exists = form.querySelector("input[name='" + formFieldName + "']");
    if (!exists) {
        throw new Error('Form field ' + formFieldName + ' does not exist');
    }
    if (inputsInvalid) {
        const inputsInvalidArray = Array.from(inputsInvalid);
        for (let inputField of inputsInvalidArray) {
            if (inputField.name === formFieldName) {
                return false;
            }
        }
    }
    return true;
}

///////////////////////////////////////////////////
//////////// LOAD STEP - MODAL POPUP
///////////////////////////////////////////////////
export const loadStep = async (stepName) => {
    const el = document.querySelector(`[data-name=${stepName}]`);
    if (el) {
        await nextTick();
        return el;
    }

    let found = null;
    const availableSteps = [...window.popups, ...window.modals];
    for (const step of availableSteps) {
        if (step.name == stepName) {
            found = step;
            break;
        }
    }

    if (!found) throw 'Cannot find step ' + stepName;
    const html = await apiProvider.loaderGet(found.url);

    // append child using a intermediate div
    const div = document.createElement('div');
    div.setAttribute('data-step-container', stepName); //only for debug purpose
    div.innerHTML = html;
    document.getElementById('mp-container').appendChild(div);

    // get step element
    const result = document.querySelector(`[data-name=${stepName}]`);
    forceReflow(); // necessary to run transitions on element
    return result;
};

/**
 * Forces restyle and reflow
 * @returns
 */
export const forceReflow = () => {
    return document.body.offsetWidth;
};

/**
 * Base64 URL Encode
 * @param {*} data
 * @returns
 */
export const base64url_encode = (data) => {
    return btoa(data).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

/**
 * Base64 URL Decode
 * @param {*} value
 * @returns
 */
export const base64url_decode = (value) => {
    const m = value.length % 4;
    return atob(
        value
            .replace(/-/g, '+')
            .replace(/_/g, '/')
            .padEnd(value.length + (m === 0 ? 0 : 4 - m), '=')
    );
};
