import Component from '../../../../../../libs/components/component';
import { informativaManager } from '../../../../../../libs/informativa-manager';
import { storeManager } from '../../../../../../libs/store-manager';

export default class FooterConfirmMailGcc extends Component {
    constructor(name, root) {
        super(name, root);
        this.privacyLinkContainer = this._dEl('privacyLinkContainer');
        this.customerServiceText = this._dEl('customerServiceText');
        this.privacyLinkLabel = this.root.dataset.privacyLinkLabel;
        this.email = this.root.dataset.email;
        this.emailLabel = this.root.dataset.emailLabel;
        this._addStoreListeners();
        this.user = storeManager.get('userData');
        this.pdv = storeManager.get('selectedPdv');
        this.setPrivacyLink(this.coop, this.isCard);
        this.setEmail();
    }

    _addStoreListeners() {
        storeManager.on('selectedPdv', (path, data) => {
            this.pdv = data;
            this.setPrivacyLink();
        });

        storeManager.on('userData', (path, data) => {
            this.user = data;
            this.setPrivacyLink();
        });

        this.setPrivacyLink();
    }

    setEmail() {
        this.customerServiceText.innerHTML = this.customerServiceText.innerHTML.replace(
            '$(email)',
            `<a href="mailto: ${this.email ? this.email : 'conadcard@carteconad.it'}">${
                this.emailLabel ? this.emailLabel : this.email ? this.email : 'conadcard@carteconad.it'
            }</a>`
        );
    }

    async setPrivacyLink() {
        if (!this.privacyLinkContainer) {
            return;
        }
        const result = await informativaManager.getInformativa(this.user, this.pdv);

        this.privacyLinkContainer.innerHTML = this.privacyLinkContainer.innerHTML.replace(
            '$(link)',
            `<a href="${result?.linkPdfInformativa ? result.linkPdfInformativa : '#'}">${
                this.privacyLinkLabel ? this.privacyLinkLabel : 'Privacy policy'
            }</a>`
        );
    }
}
