import { register } from './libs/register';
import Alpine from 'alpinejs';

/* Content */
import './repository/apps/conad-myconad/components/content/mc1-richtext/script';
import './repository/apps/conad-myconad/components/content/mc4-alert-box/script';
import Iframe from './repository/apps/conad-myconad/components/content/mc5-iframe/script';
import DashboardPreferred from './repository/apps/conad-myconad/components/content/mc6-dashboard-preferred/script';
import PreviewCoupon from './repository/apps/conad-myconad/components/content/mc9-preview-coupon/script';
import CardsIconText from './repository/apps/conad-myconad/components/content/mc8-cards-icon-text/script';
import StrilloApp from './repository/apps/conad-myconad/components/content/mc7-strillo-app/script';
import ConfirmMailGcc from './repository/apps/conad-myconad/components/content/mc11-confirm-mail-gcc/script';

export const registerCommons = () => {
    //register.registerClass('.mc4-alert-box', AlertBox);
    register.registerClass('.mc5-iframe', Iframe);
    register.registerClass('.mc6-dashboard-preferred', DashboardPreferred);
    register.registerClass('.mc7-strillo-app', StrilloApp);
    register.registerClass('.mc8-cards-icon-text', CardsIconText);
    register.registerClass('.mc9-preview-coupon', PreviewCoupon);
    register.registerClass('.mc11-confirm-mail-gcc', ConfirmMailGcc);
};

/* Functional */
import Payments from './repository/apps/conad-myconad/components/functional/mf1-payments/script';
import Messages from './repository/apps/conad-myconad/components/functional/mf2-messages/script';
import Coupons from './repository/apps/conad-myconad/components/functional/mf3-coupons/script';
import Addresses from './repository/apps/conad-myconad/components/functional/mf4-addresses/script';
import Orders from './repository/apps/conad-myconad/components/functional/mf5-orders/script';
import OrderDetail from './repository/apps/conad-myconad/components/functional/mf6-order-detail/script';
import Profile from './repository/apps/conad-myconad/components/functional/mf7-profile/script';
import Dashboard from './repository/apps/conad-myconad/components/functional/mf8-dashboard/script';
import InsiemeCardDetail from './repository/apps/conad-myconad/components/functional/mf9-insieme-card-detail/script';
import InsiemePlusCardExpenses from './repository/apps/conad-myconad/components/functional/mf10-insieme-plus-card-expenses/script';
import Wishlist from './repository/apps/conad-myconad/components/functional/mf12-wishlist/script';
import FavoriteContent from './repository/apps/conad-myconad/components/functional/mf13-favorite-content/script';
import Travel from './repository/apps/conad-myconad/components/functional/mf14-travel/script';
import FavoritesFlyer from './repository/apps/conad-myconad/components/functional/mf15-favorites-flyer/script';
import CustomerCare from './repository/apps/conad-myconad/components/functional/mf16-customer-care/script';
import MyStores from './repository/apps/conad-myconad/components/functional/mf17-my-stores/script';
import bollini from './repository/apps/conad-myconad/components/functional/mf19-bollini/script';
import Assicurazioni from './repository/apps/conad-myconad/components/functional/mf20-assicurazioni/script';
import buoniBolliniHub from './repository/apps/conad-myconad/components/functional/mf22-bollini-buoni-hub/script';
import buoniCommunity from './repository/apps/conad-myconad/components/functional/mf21-buoni-community/script';
import mf23xConadDays from './repository/apps/conad-myconad/components/functional/mf23-conad-days/script';

import partialxConadDaysBanner from './repository/apps/conad-myconad/components/functional/mf8-dashboard/partials/conad-days-banner/script';

export const registerFunctionals = () => {
    register.registerClass('.mf1-payments', Payments);
    register.registerClass('.mf2-messages', Messages);
    register.registerClass('.mf3-coupons', Coupons);
    register.registerClass('.mf4-addresses', Addresses);
    register.registerClass('.mf5-orders', Orders);
    register.registerClass('.mf6-order-detail', OrderDetail);
    register.registerClass('.mf7-profile', Profile);
    register.registerClass('.mf8-dashboard', Dashboard);
    register.registerClass('.mf9-insieme-card-detail', InsiemeCardDetail);
    register.registerClass('.mf10-insieme-plus-card-expenses', InsiemePlusCardExpenses);
    register.registerClass('.mf12-wishlist', Wishlist);
    register.registerClass('.mf13-favorite-content', FavoriteContent);
    register.registerClass('.mf14-travel', Travel);
    register.registerClass('.mf15-favorites-flyer', FavoritesFlyer);
    register.registerClass('.mf16-customer-care', CustomerCare);
    register.registerClass('.mf17-my-stores', MyStores);
    Alpine.data('bollini', bollini);
    register.registerClass('.mf20-assicurazioni', Assicurazioni);
    Alpine.data('buoniBolliniHub', buoniBolliniHub);
    Alpine.data('buoniCommunity', buoniCommunity);
    Alpine.data('mf23xConadDays', mf23xConadDays);
    Alpine.data('partialxConadDaysBanner', partialxConadDaysBanner);
};

/* Layout */
import NavLayout from './repository/apps/conad-myconad/components/layout/ml2-navlayout/script';
import WizardLayout from './repository/apps/conad-myconad/components/layout/ml3-wizardlayout/script';

export const registerLayouts = () => {
    register.registerClass('.ml3-wizardlayout', WizardLayout);
    register.registerClass('.ml2-navlayout', NavLayout);
};

/* Modals */
import BillingAddressModal from './repository/apps/conad-myconad/components/modal/mm2-billing-address/script';
import AddCard from './repository/apps/conad-myconad/components/modal/mm3-add-card/script';
import ModalThankYou from './repository/apps/conad-myconad/components/modal/mm4-thank-you/script';
import CardAdded from './repository/apps/conad-myconad/components/modal/mm5-card-added/script';
import './repository/apps/conad-myconad/components/modal/mm6-select-pdv/script';
import SelectPdvMap from './repository/apps/conad-myconad/components/modal/mm6-select-pdv/scripts/select-pdv-map';
import SelectPdv from './repository/apps/conad-myconad/components/modal/mm6-select-pdv/scripts/select-pdv';
import DeliveryAddressModal from './repository/apps/conad-myconad/components/modal/mm7-delivery-address/script';
import DeleteAddressModal from './repository/apps/conad-myconad/components/modal/mm8-delete-address/script';
import ConfirmLoginModal from './repository/apps/conad-myconad/components/modal/mm10-confirm-login/script';
import RequestCardStep1 from './repository/apps/conad-myconad/components/modal/mm11-request-card-step1/script';
import RequestCardStep2 from './repository/apps/conad-myconad/components/modal/mm12-request-card-step2/script';
import PrivacyConsents from './repository/apps/conad-myconad/components/modal/mm13-privacy-consents/script';
import MenuMobile from './repository/apps/conad-myconad/components/modal/mm14-menu-mobile/script';
import CCRequestDetails from './repository/apps/conad-myconad/components/modal/mm15-cc-request-details/script';
import donateBollini from './repository/apps/conad-myconad/components/modal/mm16-donate-bollini/script';
import thankYouDonateBollini from './repository/apps/conad-myconad/components/modal/mm17-thank-you-donate-bollini/script';
import AddCardStep2 from './repository/apps/conad-myconad/components/modal/mm18-add-card-step2/script';
import searchCommunity from './repository/apps/conad-myconad/components/modal/mm19-search-community/script';
import donateToCommunity from './repository/apps/conad-myconad/components/modal/mm20-search-community-step2/script';
import thankYouCommunity from './repository/apps/conad-myconad/components/modal/mm21-thank-you-community/script';

export const registerModals = () => {
    register.registerClass('.mm2-billing-address', BillingAddressModal);
    register.registerClass('.mm3-add-card', AddCard);
    register.registerClass('.mm4-thank-you', ModalThankYou);
    register.registerClass('.mm5-card-added', CardAdded);
    register.registerClass('.mm6-select-pdv-map', SelectPdvMap);
    register.registerClass('.mm6-select-pdv', SelectPdv);
    register.registerClass('.mm7-delivery-address', DeliveryAddressModal);
    register.registerClass('.mm8-delete-address', DeleteAddressModal);
    register.registerClass('.mm10-confirm-login', ConfirmLoginModal);
    register.registerClass('.mm11-request-card-step1', RequestCardStep1);
    register.registerClass('.mm12-request-card-step2', RequestCardStep2);
    register.registerClass('.mm13-privacy-consents', PrivacyConsents);
    register.registerClass('.mm14-menu-mobile', MenuMobile);
    register.registerClass('.mm15-cc-request-details', CCRequestDetails);
    Alpine.data('donateBollini', donateBollini);
    Alpine.data('thankYouDonateBollini', thankYouDonateBollini);
    register.registerClass('.mm18-add-card-step2', AddCardStep2);
    Alpine.data('searchCommunity', searchCommunity);
    Alpine.data('donateToCommunity', donateToCommunity);
    Alpine.data('thankYouCommunity', thankYouCommunity);
};

/* Popups */
import CheckEmail from './repository/apps/conad-myconad/components/popup/mp1-check-email/script';
import PswRecovered from './repository/apps/conad-myconad/components/popup/mp2-psw-recovered/script';
import Logout from './repository/apps/conad-myconad/components/popup/mp3-logout/script';
import ModifyOrder from './repository/apps/conad-myconad/components/popup/mp4-modify-order/script';
import CancelOrder from './repository/apps/conad-myconad/components/popup/mp5-cancel-order/script';
import DeleteProfile from './repository/apps/conad-myconad/components/popup/mp6-delete-profile/script';
import RepeatOrder from './repository/apps/conad-myconad/components/popup/mp7-repeat-order/script';
import CartRecalculation from './repository/apps/conad-myconad/components/popup/mp8-cart-recalculation/script';
import RequestConadCard from './repository/apps/conad-myconad/components/popup/mp9-request-conad-card/script';
import UnmodifiableOrder from './repository/apps/conad-myconad/components/popup/mp10-unmodifiable-order/script';
import GccThankyou from './repository/apps/conad-myconad/components/popup/mp11-gcc-thankyou/script';
import UndeletableProfile from './repository/apps/conad-myconad/components/popup/mp12-undeletable-profile/script';
import MarketingConsent from './repository/apps/conad-myconad/components/popup/mp13-marketing-consent/script';
import CloseCCRequest from './repository/apps/conad-myconad/components/popup/mp14-close-cc-request/script';
import SubstituteMarketingEmail from './repository/apps/conad-myconad/components/popup/mp15-substitute-marketing-email/script';
import ChooseCart from './repository/apps/conad-myconad/components/popup/mp16-choose-cart/script';
import ProfilationConsent from './repository/apps/conad-myconad/components/popup/mp17-profilation-consent/script';
import GccCheckEmail from './repository/apps/conad-myconad/components/popup/mp18-gcc-check-email/script';
import EmailChangeProceed from './repository/apps/conad-myconad/components/popup/mp19-email-change-proceed/script';
import genericError from './repository/apps/conad-myconad/components/popup/mp20-generic-error/script';
import SaveProfile from './repository/apps/conad-myconad/components/popup/mp21-save-profile/script';
import CouponPopup from './repository/apps/conad-myconad/components/popup/mp22-coupon-popup/script';
import BolliniInfo from './repository/apps/conad-myconad/components/popup/mp23-bollini-info/script';
import RefreshUpdateProfile from './repository/apps/conad-myconad/components/popup/mp25-refresh-update-profile/script';
import RefreshThankYou from './repository/apps/conad-myconad/components/popup/mp26-refresh-thank-you/script';
import ReorderError from './repository/apps/conad-myconad/components/popup/mp28-reorder-error/script';
import PasswordChanged from './repository/apps/conad-myconad/components/popup/mp29-password-changed/script';
import regolamenti from './repository/apps/conad-myconad/components/popup/mp32-regolamenti/script';
import donateConfirm from './repository/apps/conad-myconad/components/popup/mp33-donate-confirm/script';
import cardistaAnteCPlus from './repository/apps/conad-myconad/components/popup/mp34-cardista-ante-c-plus/script';

export const registerPopups = () => {
    register.registerClass('.mp1-check-email', CheckEmail);
    register.registerClass('.mp2-psw-recovered', PswRecovered);
    register.registerClass('.mp3-logout', Logout);
    register.registerClass('.mp4-modify-order', ModifyOrder);
    register.registerClass('.mp5-cancel-order', CancelOrder);
    register.registerClass('.mp6-delete-profile', DeleteProfile);
    register.registerClass('.mp7-repeat-order', RepeatOrder);
    register.registerClass('.mp8-cart-recalculation', CartRecalculation);
    register.registerClass('.mp9-request-conad-card', RequestConadCard);
    register.registerClass('.mp10-unmodifiable-order', UnmodifiableOrder);
    register.registerClass('.mp11-gcc-thankyou', GccThankyou);
    register.registerClass('.mp12-undeletable-profile', UndeletableProfile);
    register.registerClass('.mp13-marketing-consent', MarketingConsent);
    register.registerClass('.mp14-close-cc-request', CloseCCRequest);
    register.registerClass('.mp15-substitute-marketing-email', SubstituteMarketingEmail);
    register.registerClass('.mp16-choose-cart', ChooseCart);
    register.registerClass('.mp17-profilation-consent', ProfilationConsent);
    register.registerClass('.mp18-gcc-check-email', GccCheckEmail);
    register.registerClass('.mp19-email-change-proceed', EmailChangeProceed);
    Alpine.data('genericError', genericError);
    register.registerClass('.mp21-save-profile', SaveProfile);
    register.registerClass('.mp22-coupon-popup', CouponPopup);
    register.registerClass('.mp23-bollini-info', BolliniInfo);
    register.registerClass('.mp25-refresh-update-profile', RefreshUpdateProfile);
    register.registerClass('.mp26-refresh-thank-you', RefreshThankYou);
    register.registerClass('.mp28-reorder-error', ReorderError);
    register.registerClass('.mp29-password-changed', PasswordChanged);
    Alpine.data('regolamenti', regolamenti);
    Alpine.data('donateConfirm', donateConfirm);
    Alpine.data('cardistaAnteCPlus', cardistaAnteCPlus);
};

/* Structure */
import Page from './repository/apps/conad-myconad/components/structure/ms1-page/script';
import Header from './repository/apps/conad-myconad/components/structure/ms2-header/script';
import Footer from './repository/apps/conad-myconad/components/structure/ms3-footer/script';
import './repository/apps/conad-myconad/components/structure/ms5-background/script';
import Preheader from './repository/apps/conad-myconad/components/structure/ms6-preheader/script';
import Menu from './repository/apps/conad-myconad/components/structure/ms7-menu/script';
import FooterConfirmMailGcc from './repository/apps/conad-myconad/components/structure/ms9-footer-confirm-mail-gcc/script';

export const registerStructures = () => {
    register.registerClass('.ms1-page', Page);
    register.registerClass('.ms2-header', Header);
    register.registerClass('.ms3-footer', Footer);
    register.registerClass('.ms6-preheader', Preheader);
    register.registerClass('.ms7-menu', Menu);
    register.registerClass('.ms9-footer-confirm-mail-gcc', FooterConfirmMailGcc);
};

/* Wizard */
import Login from './repository/apps/conad-myconad/components/wizard/mw1-login/script';
import RegisterLight from './repository/apps/conad-myconad/components/wizard/mw3-register-light/script';
import RegisterBimodal from './repository/apps/conad-myconad/components/wizard/mw5-register-bimodal/script';
import CompleteData from './repository/apps/conad-myconad/components/wizard/mw6-completedata/script';
import SelectRegistration from './repository/apps/conad-myconad/components/wizard/mw7-select-registration/script';
import InsertCard from './repository/apps/conad-myconad/components/wizard/mw8-insert-card/script';
import Privacy from './repository/apps/conad-myconad/components/wizard/mw9-privacy/script';
import ThankYou from './repository/apps/conad-myconad/components/wizard/mw10-thankyou/script';
import RecoverEmail from './repository/apps/conad-myconad/components/wizard/mw11-recover-email/script';
import RecoveredEmail from './repository/apps/conad-myconad/components/wizard/mw12-recovered-email/script';
import RecoverPsw from './repository/apps/conad-myconad/components/wizard/mw13-recover-psw/script';
import ConfirmedOperation from './repository/apps/conad-myconad/components/wizard/mw14-confirmed-operation/script';
import SetNewPsw from './repository/apps/conad-myconad/components/wizard/mw15-set-new-psw/script';
import AssociateSocial from './repository/apps/conad-myconad/components/wizard/mw16-associate-social/script';
import MigrationStep1 from './repository/apps/conad-myconad/components/wizard/mw17-migration-step1/script';
import MigrationStep2 from './repository/apps/conad-myconad/components/wizard/mw18-migration-step2/script';
import MigrationThankYou from './repository/apps/conad-myconad/components/wizard/mw19-migration-thank-you/script';
import FormGccStep1 from './repository/apps/conad-myconad/components/wizard/mw20-form-gcc-step1/script';
import FormGccStep2 from './repository/apps/conad-myconad/components/wizard/mw21-form-gcc-step2/script';
import ConfirmedProfile from './repository/apps/conad-myconad/components/wizard/mw22-confirmed-profile/script';

export const registerWizards = () => {
    register.registerClass('.mw1-login', Login);
    register.registerClass('.mw3-register-light', RegisterLight);
    register.registerClass('.mw5-register-bimodal', RegisterBimodal);
    register.registerClass('.mw6-completedata', CompleteData);
    register.registerClass('.mw7-select-registration', SelectRegistration);
    register.registerClass('.mw8-insert-card', InsertCard);
    register.registerClass('.mw9-privacy', Privacy);
    register.registerClass('.mw10-thankyou', ThankYou);
    register.registerClass('.mw11-recover-email', RecoverEmail);
    register.registerClass('.mw12-recovered-email', RecoveredEmail);
    register.registerClass('.mw13-recover-psw', RecoverPsw);
    register.registerClass('.mw14-confirmed-operation', ConfirmedOperation);
    register.registerClass('.mw15-set-new-psw', SetNewPsw);
    register.registerClass('.mw16-associate-social', AssociateSocial);
    register.registerClass('.mw17-migration-step1', MigrationStep1);
    register.registerClass('.mw18-migration-step2', MigrationStep2);
    register.registerClass('.mw19-migration-thank-you', MigrationThankYou);
    register.registerClass('.mw20-form-gcc-step1', FormGccStep1);
    register.registerClass('.mw21-form-gcc-step2', FormGccStep2);
    register.registerClass('.mw22-confirmed-profile', ConfirmedProfile);
};

/* Templates */
import Modal from './repository/apps/conad-myconad/templates/mt1-modal/script';
import AddressField from './repository/apps/conad-myconad/templates/mt2-addressfield/script';
import Store from './repository/apps/conad-myconad/templates/mt3-store/script';
import Popup from './repository/apps/conad-myconad/templates/mt4-popup/script';
import FooterLinks from './repository/apps/conad-myconad/templates/mt5-footer-links/script';
import Message from './repository/apps/conad-myconad/templates/mt7-message/script';
import Textfield from './repository/apps/conad-myconad/templates/mt8-textfield/script';
import MessagePopup from './repository/apps/conad-myconad/templates/mt9-message-popup/script';
import Filter from './repository/apps/conad-myconad/templates/mt10-filter/script';
import Loader from './repository/apps/conad-myconad/templates/mt11-loader/script';
import Coupon from './repository/apps/conad-myconad/templates/mt12-coupon/script';
import CouponPopupT from './repository/apps/conad-myconad/templates/mt13-coupon-popup/script';
import CouponInstructions from './repository/apps/conad-myconad/templates/mt14-coupon-instructions/script';
import Address from './repository/apps/conad-myconad/templates/mt15-address/script';
import Select from './repository/apps/conad-myconad/templates/mt16-select/script';
import Checkbox from './repository/apps/conad-myconad/templates/mt17-checkbox/script';
import Textarea from './repository/apps/conad-myconad/templates/mt18-textarea/script';
import Radio from './repository/apps/conad-myconad/templates/mt19-radio/script';
import Tooltip from './repository/apps/conad-myconad/templates/mt20-tooltip/script';
import Order from './repository/apps/conad-myconad/templates/mt21-order/script';
import OrderStatesPopup from './repository/apps/conad-myconad/templates/mt22-order-states-popup/script';
import DatepickerField from './repository/apps/conad-myconad/templates/mt23-datepicker/script';
import ProductAccordionCard from './repository/apps/conad-myconad/templates/mt24-product-accordion-card/script';
import Cards from './repository/apps/conad-myconad/templates/mt27-cards/script';
import IconCards from './repository/apps/conad-myconad/templates/mt28-icon-cards/script';
import Toggle from './repository/apps/conad-myconad/templates/mt29-toggle/script';
import TextfieldLocked from './repository/apps/conad-myconad/templates/mt31-textfield-locked/script';
import DateBox from './repository/apps/conad-myconad/templates/mt32-date-box/script';
import TimeslotChip from './repository/apps/conad-myconad/templates/mt33-timeslot-chip/script';
import MapPin from './repository/apps/conad-myconad/templates/mt34-map-pin/script';
import ContentCard from './repository/apps/conad-myconad/templates/mt35-content-card/script';
import Instructions from './repository/apps/conad-myconad/templates/mt36-instructions/script';
import Widget from './repository/apps/conad-myconad/templates/mt37-widget/script';
import AddressGoogle from './repository/apps/conad-myconad/templates/mt38-address-google/script';
import './repository/apps/conad-myconad/templates/mt39-info-window/script';
import PreauthorizationPopup from './repository/apps/conad-myconad/templates/mt39-preauthorization-popup/script';
import ProductGiftAccordionCard from './repository/apps/conad-myconad/templates/mt39-product-gift-accordion-card/script';
import CustomerCareRequest from './repository/apps/conad-myconad/templates/mt40-customer-care-request/script';
import FavoriteCard from './repository/apps/conad-myconad/templates/mt41-favorite-card/script';
import FavoriteFlyerProduct from './repository/apps/conad-myconad/templates/mt42-product-flyer-card/script';
import ReservationCard from './repository/apps/conad-myconad/templates/mt43-reservation-card/script';
import StoreCard from './repository/apps/conad-myconad/templates/mt44-store-card/script';
import RefreshPrizeCoupon from './repository/apps/conad-myconad/templates/mt45-refresh-prize-coupon/script';

export const registerTemplates = () => {
    register.registerClass('.mt1-modal', Modal);
    register.registerClass('.mt2-addressfield', AddressField);
    register.registerClass('.mt3-store', Store);
    register.registerClass('.mt4-popup', Popup);
    register.registerClass('.mt5-footer-links', FooterLinks);
    register.registerClass('.mt7-message', Message);
    register.registerClass('.mt8-textfield', Textfield);
    register.registerClass('.mt9-message-popup', MessagePopup);
    register.registerClass('.mt10-filter', Filter);
    register.registerClass('.mt11-loader', Loader);
    register.registerClass('.mt12-coupon', Coupon);
    register.registerClass('.mt13-coupon-popup', CouponPopupT);
    register.registerClass('.mt14-coupon-instructions', CouponInstructions);
    register.registerClass('.mt15-address', Address);
    register.registerClass('.mt16-select', Select);
    register.registerClass('.mt17-checkbox', Checkbox);
    register.registerClass('.mt18-textarea', Textarea);
    register.registerClass('.mt19-radio', Radio);
    register.registerClass('.mt20-tooltip', Tooltip);
    register.registerClass('.mt21-order', Order);
    register.registerClass('.mt22-order-states-popup', OrderStatesPopup);
    register.registerClass('.mt23-datepicker', DatepickerField);
    register.registerClass('.mt24-product-accordion-card', ProductAccordionCard);
    register.registerClass('.mt27-cards', Cards);
    register.registerClass('.mt28-icon-cards', IconCards);
    register.registerClass('.mt29-toggle', Toggle);
    register.registerClass('.mt31-textfield-locked', TextfieldLocked);
    register.registerClass('.mt32-date-box', DateBox);
    register.registerClass('.mt33-timeslot-chip', TimeslotChip);
    register.registerClass('.mt34-map-pin', MapPin);
    register.registerClass('.mt35-content-card', ContentCard);
    register.registerClass('.mt36-instructions', Instructions);
    register.registerClass('.mt37-widget', Widget);
    register.registerClass('.mt38-address-google', AddressGoogle);
    register.registerClass('.mt39-preauthorization-popup', PreauthorizationPopup);
    register.registerClass('.mt39-product-gift-accordion-card', ProductGiftAccordionCard);
    register.registerClass('.mt40-customer-care-request', CustomerCareRequest);
    register.registerClass('.mt41-favorite-card', FavoriteCard);
    register.registerClass('.mt42-product-flyer-card', FavoriteFlyerProduct);
    register.registerClass('.mt43-reservation-card', ReservationCard);
    register.registerClass('.mt44-store-card', StoreCard);
    register.registerClass('.mt45-refresh-prize-coupon', RefreshPrizeCoupon);
};

export const registerAll = () => {
    registerCommons();
    registerFunctionals();
    registerLayouts();
    registerModals();
    registerPopups();
    registerStructures();
    registerTemplates();
    registerWizards();
};
