import PopupComponent from '../../../../../../libs/components/popup-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { storeManager } from '../../../../../../libs/store-manager';
import './style.scss';

export default class CartRecalculation extends PopupComponent {
    constructor(name, root) {
        super(name, root);

        this.ctaAction = this._dEl('ctaAction');

        this._addEventListeners();
    }

    _addEventListeners() {
        this.ctaAction.addEventListener('click', (event) => {
            event.preventDefault();
            storeManager.emit('mw1DoLogin', { doLogin: true });
            flowManager.complete();
        });
    }

    _onCancel() {
        flowManager.complete();
    }
}
