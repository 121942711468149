import { apiProvider } from '../../../../../../libs/api-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { openWaitedLoader, closeWaitedLoader } from '../../../templates/mt11-loader/script';
import { userManager } from '../../../../../../libs/user-service';
import { v4 } from 'uuid';

export default function bollini() {
    return {
        showLoadMoreButton: false,
        limit: 5,
        limitOffset: 3,
        movementsLoaded: false,
        balanceLoaded: false,
        userBalance: undefined,

        cardLabels: {},
        icons: {},
        signs: {},

        movements: [],
        init() {
            this._setCardLabels();
            this._start();
        },

        async _start() {
            // reset
            this.balanceLoaded = false;
            this.movementsLoaded = false;
            this.movements = [];

            // download
            await this._setBolliniBalance();
            await this._downloadOperation();
        },

        async _setBolliniBalance() {
            const user = await userManager.getUser();
            const data = {
                fidelityCard: user.cartaFedelta,
                collectionCode: this.$root.dataset.collectionCode,
            };
            const balance = await apiProvider.getUserBalance(data);
            this.userBalance = balance.saldoCumulato - balance.saldoSpeso;
            this.balanceLoaded = true;
        },

        _setCardLabels() {
            this.cardLabels = JSON.parse(this.$root.dataset.cardLabelsJson);
            this.icons = JSON.parse(this.$root.dataset.iconsJson);
            this.signs = JSON.parse(this.$root.dataset.signsJson);
        },

        async _downloadOperation() {
            this.limit = this.$root.dataset.initialMovementsLimit
                ? this.$root.dataset.initialMovementsLimit
                : this.limit;
            this.limitOffset = this.$root.dataset.loadMoreOffset ? this.$root.dataset.loadMoreOffset : this.limitOffset;

            // get movements
            try {
                const data = {
                    codiceCollection: this.$root.dataset.collectionCode,
                };
                const movements = await apiProvider.getCardMovementsWithCollection(data);
                this.movements = movements.map((item) => {
                    const accumulated =
                        item.dettagli && (item.dettagli.length > 1 || !!item.dettagli[0].codice.match(/BAV|BSS|BAS/i));

                    const code = accumulated
                        ? 'accumulatedStamps'
                        : item.dettagli.find((e) => !!e).codice.toLowerCase();

                    let dettagli = null;
                    if (accumulated) {
                        dettagli = item.dettagli.map((det) => {
                            const causale = this.cardLabels[det.codice.toLowerCase()];
                            const sign = this.signs[det.codice.toLowerCase()];
                            const punti = Math.abs(det.punti);
                            return { causale, punti, sign };
                        });
                    }

                    return {
                        data: new Date(item.data),
                        causale: this.cardLabels[code],
                        icon: this.icons[code],
                        sign: this.signs[code],
                        punti: item.dettagli.reduce((cur, det) => cur + Math.abs(det.punti), 0),
                        dettagli,
                    };
                });
                this._isLoadMoreToShow();
            } catch (error) {
                console.error('error during call service for card movements');
            }
            this.movementsLoaded = true;
        },

        _isLoadMoreToShow() {
            if (this.limit >= this.movements.length) {
                this.showLoadMoreButton = false;
            } else {
                this.showLoadMoreButton = true;
            }
        },

        loadMore() {
            this.limit += this.limitOffset;
            this._isLoadMoreToShow();
        },

        openTrasferisciBolliniModal() {
            flowManager.startFlow({
                flowName: 'donate-bollini',
                flowSteps: [
                    { name: 'donate-bollini', disableBack: true },
                    { name: 'bollini-donated', disableBack: true },
                ],
                initialData: {
                    bollini: this.userBalance,
                    codiceCollection: this.$root.dataset.collectionCode,
                    uuid: v4(),
                },
                onComplete: (name, data) => {
                    if (data.numeroPunti) this._start();
                },
            });
        },

        getFilteredMovements() {
            return this.movements.filter((element, index) => index < this.limit);
        },

        async openRules() {
            let wLo = openWaitedLoader('main');
            try {
                // load bollini rules
                const rules = await apiProvider.getRegolamentoBollini({
                    codiceCollection: this.$root.dataset.collectionCode,
                });

                // get rule
                let foundRules = [];
                const userCoop = (await userManager.getUser()).newMappedCooperative;
                for (let rule of rules) {
                    if (rule.cooperativa.toLowerCase() == userCoop.toLowerCase()) {
                        foundRules.push({
                            title: rule.title,
                            text: rule.description,
                        });
                    }
                }

                // run flow manager
                flowManager.startFlow({
                    flowName: 'rules-popup',
                    flowSteps: [{ name: 'regolamenti-bollini' }],
                    initialData: {
                        rules: foundRules,
                    },
                });
            } catch (e) {
                console.warn(e);
            } finally {
                closeWaitedLoader('main', wLo);
            }
        },

        getDateFromDatetime(date) {
            return date.toLocaleDateString();
        },

        getTimeFromDatetime(date) {
            return date.toLocaleTimeString(undefined, { hour: 'numeric', minute: 'numeric' });
        },
    };
}
