import { apiProvider } from '../../../../../../libs/api-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import { userManager } from '../../../../../../libs/user-service';
import { checkFormWithoutValidation } from '../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';

export default function donateBollini() {
    return {
        uuid: null,
        isFormValid: false,
        isFormValidToTransferAllBollini: false,
        init() {
            this.pointsToTransferTextfield = register.getClass(this.$refs.pointsToTransfer);
            this.beneficiaryCardTextField = register.getClass(this.$refs.beneficiaryCard);
        },
        open() {
            this.$refs.form.reset();
            const data = flowManager.getDataFromFlow();
            this.bollini = data?.bollini || 0;
            this.codiceCollection = data.codiceCollection;
            this.uuid = data.uuid;
            this.pointsToTransferTextfield.setPermanentText('/' + this.bollini || '0');
            this.pointsToTransferTextfield.setValidationContext({
                balance: this.bollini,
            });
            this.checkFormValidity();
        },
        close() {},
        cancel() {
            flowManager.complete();
        },
        async submitForm() {
            // open confirm
            const formData = new FormData(this.$refs.form);
            const toDonate = parseInt(formData.get('pointsToTransfer'));
            const target = formData.get('beneficiaryCard');
            const data = await flowManager.startAsyncFlow({
                flowName: 'donate-bollini-confirm',
                flowSteps: [{ name: 'bollini-confirm-donate', disableBack: true }],
                initialData: {
                    quantity: toDonate,
                    target: target,
                },
            });

            // if not confirmed return
            if (!data?.ok) {
                return;
            }

            // check cart error
            const user = await userManager.getUser();
            if (user.cartaFedelta == target) {
                flowManager.startFlow({
                    flowName: 'bollini-donate-same-target-error',
                    flowSteps: [{ name: 'bollini-donate-same-target-error', disableBack: true }],
                });
                return;
            }

            // send donation
            try {
                openLoader('main');
                let dataForDonate = {
                    numeroPunti: toDonate,
                    codiceCartaTarget: target,
                    codiceCollection: this.codiceCollection,
                    id: this.uuid,
                };
                await apiProvider.sendBollini(dataForDonate);

                // ok, go to thankyou
                flowManager.appendDataToFlow({
                    codiceCartaTarget: target,
                    numeroPunti: toDonate,
                });
                flowManager.next();
            } catch (error) {
                console.warn(error);

                // error, show error popup
                this._showErrorPopup(error);
            } finally {
                closeLoader('main');
            }
        },
        checkFormValidity() {
            this.isFormValid = checkFormWithoutValidation(this.$refs.form);
            const formData = new FormData(this.$refs.form);
            const toDonate = parseInt(formData.get('pointsToTransfer') || '0');
            this.isFormValidToTransferAllBollini = toDonate < this.bollini;
        },
        setAllBollini() {
            const pointsToTransferField = register.getClass(this.$refs.pointsToTransfer);
            pointsToTransferField.reset();
            pointsToTransferField.setValue(this.bollini);
        },
        _showErrorPopup(_error) {
            // check error and open the correct popup ( after integration )
            flowManager.startFlow({
                flowName: 'donate-bollini-generic-error',
                flowSteps: [{ name: 'bollini-donate-generic-error', disableBack: true }],
            });
            // flowManager.startFlow({
            //     flowName: 'donate-bollini-invalid-target-error',
            //     flowSteps: [{ name: 'donate-bollini-invalid-target-error', disableBack: true }],
            // });
        },
    };
}
