import { closeWaitedLoader, openWaitedLoader } from '../repository/apps/conad-myconad/templates/mt11-loader/script';
import { register } from './register';
import { storeManager } from './store-manager';
import { loadStep } from './utils';

export class FlowManager {
    constructor() {
        this.dataFlow = window.dataFlow || {};
        this.register = register;
        this.storeManager = storeManager;
        this.initializingFlow = false;
    }

    getFlowNameByStorePath(path) {
        return /^flow\/([^/]+).*$/g.exec(path)[1];
    }

    checkInit(initialFlow) {
        if (initialFlow && initialFlow != 'undefined') {
            const flowConf = typeof initialFlow == 'string' ? JSON.parse(initialFlow) : initialFlow;
            this.startFlow(flowConf);
        }
    }

    async startAsyncFlow({ flowName, flowSteps, options = {}, initialData = null }) {
        return await new Promise((resolve) => {
            this.startFlow({
                flowName,
                flowSteps,
                options,
                initialData,
                onComplete: (name, data) => {
                    resolve(data);
                },
            });
        });
    }

    async startFlow({ flowName, flowSteps, options = {}, onComplete = null, initialData = null }) {
        if (!flowSteps || flowSteps.length <= 0) {
            console.warn('Cannot start flow without steps');
            return;
        }

        if (this.initializingFlow) {
            console.warn('Cannot start another flow during a running flow initializing');
            return;
        }

        this.initializingFlow = true;
        let loaderId = openWaitedLoader('main');

        try {
            const steps = [];
            for (const stepConf of flowSteps) {
                const stepName = stepConf.name;
                let stepEl = document.querySelector(`[data-name='${stepName}']`);
                if (!stepEl) stepEl = await loadStep(stepName);
                const stepObj = this.register.getClass(stepEl);
                if (!stepObj) throw `Cannot get step object ${stepName}`;

                steps.push({
                    name: stepName,
                    el: stepEl,
                    index: stepConf.stepIndex,
                    disableBack: stepConf.disableBack,
                    options: stepConf.options,
                    initialStep: stepConf.initialStep,
                });
            }

            // get the first step to open
            let startStep = steps[0];
            for (const step of steps) {
                if (step.initialStep) {
                    startStep = step;
                    break;
                }
            }

            // load back stack
            const backStack = [];
            for (const step of steps) {
                if (step == startStep) break;
                backStack.push(step);
            }

            this.storeManager.emit(`flow/${flowName}`, { flowName, steps, options, onComplete });
            this.storeManager.emit(`flow/${flowName}/data`, initialData);
            const currentFlow = this.storeManager.get('currentFlow') || [];
            currentFlow.unshift(flowName);
            this.storeManager.emit('currentFlow', currentFlow);
            this._goTo(startStep, backStack, flowName);
            console.log(`Started flow with name ${flowName}`);
        } catch (e) {
            console.warn(`Cannot start flow ${flowName}`, e);
        } finally {
            this.initializingFlow = false;
            closeWaitedLoader('main', loaderId);
        }
    }

    /**
     * Go To Next step ( target specifies the next step )
     * @param {*} target
     */
    next(target, flowName, reset = false) {
        const currentStep = this.storeManager.get(this._getFlowStorePath(flowName, 'activeStep'));
        const backStack = this.storeManager.get(this._getFlowStorePath(flowName, 'backStack')) || [];
        const flow = this.storeManager.get(this._getFlowStorePath(flowName));
        if (!flow || !currentStep) return;

        let step = null;
        if (target) {
            step = this._getByName(target, flowName);
        } else {
            const index = this._getStepIndex(flowName);
            if (index < flow.steps.length - 1) step = flow.steps[index + 1];
        }
        if (!step) return;
        backStack.push(currentStep);
        this._goTo(step, backStack, flowName, reset);
    }

    /**
     * Back to a previous step ( target specifies the back step )
     * @param {*} target
     */
    back(target, flowName) {
        let backStack = this.storeManager.get(this._getFlowStorePath(flowName, 'backStack')) || [];
        if (!backStack || backStack.length <= 0) return;

        const stepName = target || backStack[backStack.length - 1].name;

        let step = null;
        let i = backStack.length - 1;
        for (i = backStack.length - 1; i >= 0; i--) {
            const tmpStep = backStack[i];
            if (stepName == tmpStep.name) {
                step = tmpStep;
                break;
            }
        }
        if (!step) return;
        backStack = backStack.slice(0, i);
        this._goTo(step, backStack, flowName);
    }

    /**
     * Completes the flow if no back is found or get back if there is a back stack
     */
    backOrComplete(flowName) {
        const backStack = this.storeManager.get(this._getFlowStorePath(flowName, 'backStack')) || [];
        if (backStack && backStack.length > 0) {
            this.back(flowName);
        } else {
            this.complete(flowName);
        }
    }

    complete(flowName) {
        //close steps
        const activeStepPath = this._getFlowStorePath(flowName, 'activeStep');
        const currentStep = this.storeManager.get(activeStepPath);
        this.register.getClass(currentStep.el).close();

        //get flow
        const flow = this.storeManager.get(this._getFlowStorePath(flowName));

        //remove active flow
        flowName = flowName ? flowName : this.storeManager.get('currentFlow')[0];
        const currentFlow = this.storeManager.get('currentFlow');
        currentFlow.splice(currentFlow.indexOf(flowName), 1);
        this.storeManager.emit('currentFlow', currentFlow);

        //trigger onComplete
        if (flow.onComplete) flow.onComplete(flowName, this.storeManager.get(this._getFlowStorePath(flowName, 'data')));

        //send complete log
        console.log(`Completed flow: ${flowName}`);
    }

    appendDataToFlow(data, flowName) {
        this.storeManager.emit(this._getFlowStorePath(flowName, 'data'), data, true);
    }

    getDataFromFlow(flowName) {
        return this.storeManager.get(this._getFlowStorePath(flowName, 'data'));
    }

    getOptionsFromFlow(flowName) {
        return this.storeManager.get(this._getFlowStorePath(flowName, 'options'));
    }

    getCurrentFlowName() {
        return this.storeManager.get('currentFlow')[0];
    }

    _goTo(step, backStack, flowName, reset = false) {
        if (this.storeManager.get(this._getFlowStorePath(flowName, 'activeStep'))) {
            const currentStep = this.storeManager.get(this._getFlowStorePath(flowName, 'activeStep'));
            const obj = this.register.getClass(currentStep.el)
            if (obj.isOpen()) {
                obj.close();
            }
        }
        this.storeManager.emit(this._getFlowStorePath(flowName, 'activeStep'), step);
        this.storeManager.emit(this._getFlowStorePath(flowName, 'state'), this._getState(step, backStack, flowName));
        this.storeManager.emit(this._getFlowStorePath(flowName, 'backStack'), reset ? [] : backStack);
        this.register.getClass(step.el).open();
    }

    _getStepIndex(flowName) {
        const currentStep = this.storeManager.get(this._getFlowStorePath(flowName, 'activeStep'));
        const flow = this.storeManager.get(this._getFlowStorePath(flowName));
        if (!currentStep || !flow) return -1;

        let currentStepIndex = -1;
        let index = 0;
        for (const step of flow.steps) {
            if (step.name == currentStep.name) {
                currentStepIndex = index;
                break;
            }
            index++;
        }
        return currentStepIndex;
    }

    _getByName(name, flowName) {
        const flow = this.storeManager.get(this._getFlowStorePath(flowName));
        if (!flow) return null;
        for (const step of flow.steps) {
            if (step.name == name) {
                return step;
            }
        }
        return null;
    }

    _getState(currentStep, backStack, flowName, reset = false) {
        const flow = this.storeManager.get(this._getFlowStorePath(flowName));
        if (!flow) return;

        const backNames = backStack.map((item) => item.name);

        const result = {};
        if (reset) return result;

        let status = 'past';
        for (const step of flow.steps) {
            //skip if step doesn't want index
            if (!step.index) continue;

            if (step.name == currentStep.name) status = 'active';
            else if (status == 'active') status = 'future';

            const arr = result[step.index] || [];
            arr.push({
                name: step.name,
                status: status == 'past' && backNames.indexOf(step.name) < 0 ? 'disabled' : status,
                index: step.index,
            });
            result[step.index] = arr;
        }
        return result;
    }

    _getFlowStorePath(flowName, action) {
        if (!flowName) {
            flowName = this.storeManager.get('currentFlow')[0];
        }
        if (action) {
            return `flow/${flowName}/${action}`;
        }
        return `flow/${flowName}`;
    }
}

const defaultFlowManager = new FlowManager();
window.rcFlowManager = defaultFlowManager;

/**
 * @returns {FlowManager} The flow manager
 */
export const getFlowManager = () => {
    return window.rcFlowManager;
};

export const flowManager = defaultFlowManager;
