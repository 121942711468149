import PerfectScrollbar from 'perfect-scrollbar';
import ValidableComponent from '../../../../../libs/components/validable-component';
import itemTpl from './jscall-item.html';
import { debounce, emptyElement, isInViewport, transitionEnd } from '../../../../../libs/utils';
import { runWithData } from '../../../../../libs/htl-runtime/HTMLRuntime';
import { dictionary } from '../../../../../libs/dictionary-provider';
import { register } from '../../../../../libs/register';
import './style.scss';

export default class Select extends ValidableComponent {
    constructor(name, root) {
        super(name, root);

        this.page = document.querySelector('.ms1-page');
        this.id = this.root.id;
        this.button = this._dEl('button');
        this.label = this._dEl('label');
        this.selectLabel = this._dEl('selectLabel');
        this.input = this._dEl('input');
        this.inputLabel = this._dEl('inputLabel');
        this.content = this._dEl('content');
        this.error = this._dEl('error');
        this.items = this._dEl('item', true);
        this.selectedItem = this.items.filter((item) => item.classList.contains('selected'))[0];
        this.selValue = this.input.value;
        this.keyDownText = '';
        this.openedAt = Date.now();

        const scrollbarOptions = {
            swipeEasing: true,
            suppressScrollX: true,
        };
        this.ps = new PerfectScrollbar(this.content, scrollbarOptions);

        this._addEventListeners();
    }

    _getInput() {
        return this.input;
    }

    _addEventListeners() {
        /* open dropdown */
        this.button.addEventListener('click', (event) => {
            event.preventDefault();
            /* close other select dropdowns on the page */
            this.page.querySelectorAll(this.getSelector()).forEach((sel) => {
                if (sel.id === this.id) return;
                let selClass = register.getClass(sel);
                selClass.closeDropdown();
            });

            if (this.root.classList.contains(this._mod('open'))) {
                this.closeDropdown();
                this._controlRequired();
            } else {
                this.openDropdown();
            }
        });

        /* select option */
        ['click', 'keydown'].forEach((e) => {
            this.content.addEventListener(e, (event) => {
                if (event.type === 'keydown' && event.key !== 'Enter') return;
                const item = event.target.closest(this._el('item', true));
                if (!item) return;
                event.preventDefault();

                // if we have a value in item, than select it.. otherwise reset select
                if (item.dataset.value) {
                    this.setState('valid');
                    this.setSelected(item.dataset.value);
                } else {
                    this.reset();
                    this._changedInput();
                }

                this.closeDropdown();
                this.button.focus();
            });
        });

        /* invalid field, set message */
        this.input.addEventListener('invalid', () => {
            if (!this.isValid()) {
                this.setState('error');
                this._requireField();
            }
        });
        this.input.addEventListener('change', () => {
            this.input.setCustomValidity('');
        });
        ['keyup', 'change', 'input', 'focusout'].forEach((e) => {
            this.input.addEventListener(e, () => {
                if (e.type === 'focusout') this.keyDownText = '';
                this._checkState();
            });
        });

        /* debounced scrolling to best match option on keydown */
        const scrollDebounced = debounce((text) => {
            const scored = [...this.items]
                .map((item) => {
                    let label = item.querySelector(this._el('option', true))?.innerText?.toLowerCase();
                    return {
                        item: item,
                        label: label,
                        score: label.indexOf(text),
                    };
                })
                .filter((x) => x.score >= 0)
                .sort((x, y) => x.score - y.score);
            if (scored.length <= 0) return;
            const bestOption = scored[0];
            this.content.scrollTop = bestOption.item.offsetTop;
            bestOption.item?.focus();
            this.keyDownText = '';
        }, 800);
        this.root.addEventListener('keydown', (event) => {
            if (['Enter', 'Tab', 'Shift'].includes(event.key)) return;
            event.preventDefault();
            if (!event.key.match(/^[a-zA-Z]$/)) {
                event.stopPropagation();
                return;
            }
            this.keyDownText += event.key;
            scrollDebounced(this.keyDownText);
            event.stopPropagation();
        });

        /* fix parent scrolling on mobile */
        this.content.addEventListener('touchmove', (event) => {
            event.stopPropagation();
        });

        /* close on click outside */
        this._addListener(
            'click',
            (event) => {
                const target = event.target;
                if (!target.closest(`.${this.name}`)) {
                    this.closeDropdown();
                }
            },
            document
        );
    }

    _requireField() {
        this.setErrorText(dictionary.getFEMessage('requiredField'));
    }

    /* override */
    setErrorText(errorText) {
        super.setErrorText(errorText);
        if (typeof errorText !== 'string' || errorText === '') return;
        this.error.innerText = errorText;
    }

    _controlRequired() {
        if (this.input.required && this.input.value == '') {
            if (!this.root.classList.contains(this._mod('errorState'))) {
                this.trackFormError(dictionary.getFEMessage('requiredField'));
            }
            this.setState('error');
            this._requireField();
        }
    }

    async _scrollIntoView() {
        if (!this.root.classList.contains(this._mod('open'))) return;
        if (!this.content) return;
        await transitionEnd(this.content);
        if (isInViewport(this.content)) return;
        this.content.scrollIntoView();
    }

    openDropdown() {
        if (this.root.classList.contains(this._mod('open'))) return;
        this.root.classList.add(this._mod('open'));
        this.openedAt = Date.now();
        this._scrollIntoView();
    }

    closeDropdown() {
        const now = Date.now();
        if (now - this.openedAt < 200) return;

        if (!this.root.classList.contains(this._mod('open'))) return;
        this.root.classList.remove(this._mod('open'));

        //check required
        this._controlRequired();

        setTimeout(() => {
            this.button?.focus();
        }, 200);
    }

    setSelected(value) {
        //get item
        const item = this.content.querySelector(`${this._el('item', true)}[data-value="${value}"]`);
        if (!item) return;

        this._resetItems();
        item.classList.add('selected');
        this.selectedItem = item;
        this.label.textContent = item.textContent;
        this._showLabel();
        this.selValue = value;
        this.input.value = item.dataset.value;
        this.inputLabel.value = item.textContent.trim();
        this._changedInput();
    }

    setItems(items) {
        //empty dropdown
        emptyElement(this.content);
        //populate dropdown
        const documentFragment = document.createDocumentFragment();
        for (const item of items) {
            const el = runWithData(itemTpl, item);
            documentFragment.appendChild(el);
        }
        this.content.appendChild(documentFragment);
        //set items and get selected items
        this.items = Array.from(this._dEl('item', true));
        this.selectedItem = this.items.filter((item) => item.classList.contains('selected'))[0];
        //apply pre-selected value
        if (this.selectedItem) {
            this.setSelected(this.selectedItem.dataset.value);
        }
    }

    _showLabel() {
        if (!this.label.classList.contains(this._elMod('label', 'hidden'))) return;
        this.label.classList.remove(this._elMod('label', 'hidden'));
    }
    _hideLabel() {
        if (this.label.classList.contains(this._elMod('label', 'hidden'))) return;
        this.label.classList.add(this._elMod('label', 'hidden'));
    }

    getSelected() {
        return this.selectedItem;
    }

    getLabel() {
        return this.getSelected() ? this.getSelected().querySelector(this._el('option', true)).textContent : '';
    }

    getName() {
        return this.root.dataset.name;
    }

    getValue() {
        return this.getSelected() ? this.getSelected().dataset.value : '';
    }

    isValid() {
        if (!this._getInput().required) return true;
        return this.items.map((item) => item.dataset.value).filter((val) => val === this.input.value).length == 1;
    }

    reset() {
        this._resetItems();
        this.label.textContent = '';
        this._hideLabel();
        this.selValue = '';
        this.input.value = '';
        this.inputLabel.value = '';
        this.closeDropdown();
        this.setState('');
    }

    _resetItems() {
        this.items.forEach((item) => {
            if (item.classList.contains('selected')) {
                item.classList.remove('selected');
            }
        });
    }

    enable() {
        if (!this.button.classList.contains(this._elMod('button', 'disabled'))) return;
        this.button.classList.remove(this._elMod('button', 'disabled'));
        this.button.removeAttribute('disabled');
    }

    disable() {
        if (this.button.classList.contains(this._elMod('button', 'disabled'))) return;
        this.button.classList.add(this._elMod('button', 'disabled'));
        this.button.setAttribute('disabled', 'true');
    }

    isDisabled() {
        return this.button.classList.contains(this._elMod('button', 'disabled'));
    }

    /* override */
    _checkState() {
        this.input.setCustomValidity('');
        if (!this.isValid()) {
            this.setState('error');
        } else if (this.isValid() && this.getValue()) {
            this.setState('valid');
        } else {
            this.setState('');
        }
    }
}
