import Component from '../../../../../libs/components/component';
import { apiProvider } from '../../../../../libs/api-provider';
import { closeLoader, openLoader } from '../mt11-loader/script';
import './style.scss';

export default class FavoriteCard extends Component {

    constructor(name, root) {
        super(name, root);
        
        this.heartButton = this._dEl('heart')
        
        this.oneCall = false;
        this.favId = this.root.dataset.favId;
        this.favUrl = this.root.dataset.favUrl;
        this._addEventListeners();
    }

    _addEventListeners(){
        this._addListener(
            'click',
            (e) => {
                e.stopPropagation();
                if(!this.oneCall) this._removeFavoriteCall() 
            },
            this.heartButton
        )
        this._addListener(
            'click',
            (e) => {
                e.stopPropagation();
                if(this.favUrl) window.location = this.favUrl;  
            },
            this.root
        )
    }

    async _removeFavoriteCall(){
        this.oneCall = true;
        try{
            openLoader('main');
            const response = await apiProvider.removeComplementaryData(this.favId);
            if(response){
                this._emit('mf14RemoveATravelFavorite', {});
                this._toggleFavorite();
                this.root.classList.add(this._mod("fadeOut"));
                setTimeout(() => this.root.style="display: none;", 600);
            }
        }catch(err){
            console.error(err);
        }finally{
            closeLoader('main');
            this.oneCall = false;
        }    
    }

    _toggleFavorite(){
        const icon = this._dEl('heartIcon');
        if(icon){
            icon.classList.toggle('ec-icon-like-off');
            icon.classList.toggle('ec-icon-like-on');
        }
    }
}


