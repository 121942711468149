import { flowManager } from '../../../../../../libs/flow-manager';

export default function donateConfirm() {
    return {
        titleTpl: '',
        init() {
            this.titleTpl = this.$refs.title.innerHTML;
            this.plural = this.$root.dataset.pluralName;
            this.singular = this.$root.dataset.singularName;
        },
        before() {
            const data = flowManager.getDataFromFlow();
            const target = data.target;
            const quantity = data.quantity;
            let quantityText = `${quantity} ${this.singular}`;
            if (quantity > 1) {
                quantityText = `${quantity} ${this.plural}`;
            }
            this.$refs.title.innerHTML = this.titleTpl.replace('{0}', quantityText).replace('{1}', target);
        },
        open() {},
        close() {},
        cancel() {
            flowManager.complete();
        },
        confirm() {
            flowManager.appendDataToFlow({ ok: true });
            flowManager.complete();
        },
    };
}
