import './style.scss';

export default function mf23xConadDays() {
    const HOW_MANY_MORE_CARDS = 10;
    const SWIPER_CREDITS_ARCHIVED_OPT = {
        // Default parameters
        direction: 'horizontal',
        slidesPerView: 3,
        spaceBetween: 0,
        observer: true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        scrollbar: {
            el: '.swiper-scrollbar',
            draggable: true,
            dragSize: 'auto',
        },
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 1024px
            1024: {
                slidesPerView: 5,
                spaceBetween: 0,
            },
        },
    };
    let swiperCreditsAchieved;

    return {
        initialized: false,
        activeTab: 'credits',
        creditsAchievedCards: [],
        creditsAccumulationThreshold: 0, // provided by model
        couponValue: 0, // provided by model
        spendingThreshold: 0, // provided by model
        userCredits: 0, // provided by model
        totalCouponsNumber: 0, // calculated
        totalCouponsGain: 0, // calculated
        creditsToNextCoupon: 0, // calculated
        init() {
            if (!this.$root.dataset?.creditsAccumulationThreshold) return;
            if (!this.$root.dataset?.couponValue) return;

            this.creditsAccumulationThreshold = Number(this.$root.dataset?.creditsAccumulationThreshold);
            this.couponValue = Number(this.$root.dataset?.couponValue);
            this.spendingThreshold = Number(this.$root.dataset?.spendingThreshold);
            this.userCredits = Number(this.$root.dataset?.userCredits || 0);

            if (this.creditsAccumulationThreshold <= 0) return;
            if (this.couponValue <= 0) return;

            this.totalCouponsNumber = Math.trunc(this.userCredits / this.creditsAccumulationThreshold);
            this.totalCouponsGain = this.totalCouponsNumber * this.couponValue;
            this.creditsToNextCoupon =
                this.creditsAccumulationThreshold - (this.userCredits % this.creditsAccumulationThreshold);

            this._loadCreditsAchieved();
            this.initialized = true;
        },
        // internal function
        async _loadCreditsAchieved() {
            this.creditsAchievedCards = this._generateCards();
            let lastArchievedCardIndex = this.creditsAchievedCards
                ? this.creditsAchievedCards.findIndex((card) => card?.state === 'last-archieved')
                : 0;
            lastArchievedCardIndex = Math.max(lastArchievedCardIndex, 0);

            const SwiperModule = await import('swiper');
            const Swiper = SwiperModule.Swiper;
            const Navigation = SwiperModule.Navigation;
            const Scrollbar = SwiperModule.Scrollbar;
            Swiper.use([Navigation, Scrollbar]);
            swiperCreditsAchieved = new Swiper(this.$refs.swiperCreditsAchieved, SWIPER_CREDITS_ARCHIVED_OPT);
            swiperCreditsAchieved.slideTo(lastArchievedCardIndex, 1000);
        },
        _generateCards() {
            const to =
                this.userCredits -
                (this.userCredits % this.creditsAccumulationThreshold) +
                this.creditsAccumulationThreshold * HOW_MANY_MORE_CARDS;
            let cards = [];
            for (let creditsValue = 0; creditsValue <= to; creditsValue += this.creditsAccumulationThreshold) {
                let card = { credits: creditsValue };
                if (creditsValue <= this.userCredits) {
                    if (creditsValue + this.creditsAccumulationThreshold <= this.userCredits) card.state = 'archieved';
                    else card.state = 'last-archieved';
                }
                cards.push(card);
            }
            return cards; /* -> output_example = [
                { credits: 0, state: 'archieved' },
                { credits: 10, state: 'last-archieved' },
                { credits: 20 },
                { credits: 30 },
                ...
            ];*/
        },
        _singolarize(phrase, singolar = false) {
            if (!phrase) return phrase;
            // 'buoni spesa' | 'buono spesa'
            phrase = phrase.replaceAll(/([Bb]{1}uon)i( [Ss]{1}pesa)/g, `$1${singolar ? 'o' : 'i'}$2`);
            // 'crediti accumulati' | 'credito accumulato'
            phrase = phrase.replaceAll(
                /([Cc]{1}redit)i( [Aa]{1}ccumulat)i/g,
                `$1${singolar ? 'o' : 'i'}$2${singolar ? 'o' : 'i'}`,
            );
            // 'crediti' | 'credito'
            phrase = phrase.replaceAll(/([Cc]{1}redit)i/g, `$1${singolar ? 'o' : 'i'}`);
            return phrase;
        },
        // main component functions
        fillPlaceholders(singolarize = false, singolar = false) {
            if (!this.$el?.innerHTML) return;
            let content = this.$el.innerHTML;
            content = content.replaceAll('{creditsAccumulationThreshold}', this.creditsAccumulationThreshold);
            content = content.replaceAll('{couponValue}', this.couponValue);
            content = content.replaceAll('{spendingThreshold}', this.spendingThreshold);
            content = content.replaceAll('{userCredits}', this.userCredits);
            content = content.replaceAll('{totalCouponsNumber}', this.totalCouponsNumber);
            content = content.replaceAll('{totalCouponsGain}', this.totalCouponsGain);
            content = content.replaceAll('{creditsToNextCoupon}', this.creditsToNextCoupon);
            if (singolarize) content = this._singolarize(content, singolar);
            return content;
        },
        singolarization(singolar = false) {
            if (!this.$el?.innerHTML) return;
            return this._singolarize(this.$el.innerHTML, singolar);
        },
    };
}
