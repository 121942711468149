import { flowManager } from '../../../../../../libs/flow-manager';
import PerfectScrollbar from 'perfect-scrollbar';

export default function regolamenti() {
    return {
        mainExpanded: window.innerWidth >= 1024,
        fMainExpanded: window.innerWidth >= 1024,
        rules: [],
        before() {
            const data = flowManager.getDataFromFlow();
            if (data?.rules)
                this.rules = data.rules.map((item) => ({
                    title: item.title,
                    text: item.text,
                    expanded: window.innerWidth >= 1024,
                    fExpanded: window.innerWidth >= 1024,
                }));
        },
        open() {
            // create ps scrollbar
            const scrollbarOptions = {
                swipeEasing: true,
                suppressScrollX: true,
            };
            this.ps = new PerfectScrollbar(this.$refs.content, scrollbarOptions);
        },
        cancel() {
            flowManager.complete();
        },
    };
}
