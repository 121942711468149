import { apiProvider } from '../../../../../../libs/api-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';

export default function searchCommunity() {
    let engine = null;

    return {
        selectedSchool: undefined,
        filters: {
            startup: false,
            name: '',
            region: undefined,
            province: undefined,
            city: undefined,
            pageSize: 5,
            page: 1,
        },
        scuoleFiltered: [],
        pagination: undefined,

        init() {
            this.nameCl = register.getClass(this.$refs.name);
            this.regionCl = register.getClass(this.$refs.region);
            this.provinceCl = register.getClass(this.$refs.province);
            this.cityCl = register.getClass(this.$refs.city);

            // when filters changes, than populate scuole filtered
            this.$watch('filters', () => {
                this.populateScuoleFiltered();
            });
        },

        before() {
            this._reset();
        },

        open() {
            setTimeout(() => {
                this.startManager();
            }, 300);
        },

        cancel() {
            flowManager.complete();
        },

        async startManager() {
            try {
                openLoader('main');

                // await initializations of itemjs
                const itemsjs = (await import('itemsjs')).default;

                // get schools
                const schools = (await apiProvider.getBuoniSchools())?.data || [];

                // load engine
                engine = itemsjs(schools, {
                    sortings: {
                        itemName_asc: {
                            field: 'itemName',
                            order: 'asc',
                        },
                    },
                    aggregations: {
                        region: {
                            title: 'Region',
                            hide_zero_doc_count: true,
                            sort: 'term',
                            size: 100,
                        },
                        province: {
                            title: 'Province',
                            hide_zero_doc_count: true,
                            sort: 'term',
                            size: 100,
                        },
                        city: {
                            title: 'City',
                            hide_zero_doc_count: true,
                            sort: 'term',
                            size: 100,
                        },
                    },
                    searchableFields: ['itemName'],
                });

                // populate regions
                const region = this.aggregation('region');
                this.regionCl.setItems(region);
                this.filters = {
                    ...this.filters,
                    startup: true,
                };

                // reset
                this._reset();
            } catch (e) {
                console.warn(e);
            } finally {
                closeLoader('main');
            }
        },

        _reset() {
            // reset name
            this.nameCl.reset();
            // reset selects
            this.regionCl.reset();
            this.provinceCl.reset();
            this.provinceCl.setItems([{ value: '', label: 'Select Province' }]);
            this.provinceCl.disable();
            this.cityCl.reset();
            this.cityCl.setItems([{ value: '', label: 'Select City' }]);
            this.cityCl.disable();

            // reset
            this.scuoleFiltered = [];
            this.pagination = undefined;
            this.selectedSchool = undefined;
            this.filters = {
                ...this.filters,
                name: '',
                region: undefined,
                province: undefined,
                city: undefined,
                pageSize: 5,
                page: 1,
            };
        },

        populateScuoleFiltered() {
            // if not initialized returns
            if (!this.filters.startup) {
                this.scuoleFiltered = [];
                return;
            }

            // if no filter selected
            if (!this.filters.name && !this.filters.region) {
                this.scuoleFiltered = [];
                return;
            }

            // make search
            const filters = this.filters;
            const { items, pagination } = this.doSearch(filters);

            // populate scuoleFiltered & pagination
            this.scuoleFiltered.push(...items);
            this.pagination = pagination;
        },

        changed(type) {
            const form = this.$refs.form;

            // reset subselects if region or province selected
            switch (type) {
                case 'region':
                    this.provinceCl.reset();
                    this.provinceCl.setItems([{ value: '', label: 'Select Province' }]);
                    this.cityCl.reset();
                    this.cityCl.setItems([{ value: '', label: 'Select City' }]);
                    break;
                case 'province':
                    this.cityCl.reset();
                    this.cityCl.setItems([{ value: '', label: 'Select City' }]);
            }

            // get form data, after reset!
            const formData = new FormData(form);
            const name = formData.get('name') || '';
            const region = formData.get('region') || undefined;
            const province = formData.get('province') || undefined;
            const city = formData.get('city') || undefined;

            // if region is selected, than populate the province
            if (region) {
                this.provinceCl.setItems(
                    this.aggregation('province', {
                        region: region,
                    })
                );
                this.provinceCl.enable();
            } else this.provinceCl.disable();

            // if province is selected, than populate the city
            if (province) {
                this.cityCl.setItems(
                    this.aggregation('city', {
                        region: region,
                        province: province,
                    })
                );
                this.cityCl.enable();
            } else this.cityCl.disable();

            //reset
            this.scuoleFiltered = [];
            this.pagination = undefined;
            this.selectedSchool = undefined;
            // set filters
            this.filters = {
                startup: this.filters.startup,
                name,
                region,
                province,
                city,
                pageSize: 5,
                page: 1,
            };

            console.log('Changed');
        },

        loadMore() {
            this.filters = {
                ...this.filters,
                page: this.filters.page + 1,
            };

            console.log('Page change');
        },

        get filterSet() {
            return this.filters.name || this.filters.region;
        },

        async saveSchool() {
            // save school
            try {
                openLoader('main');
                await apiProvider.setBuoniPreferred({ preferred: this.selectedSchool.itemCode });

                // continue or complete the flow
                const data = flowManager.getDataFromFlow();
                flowManager.appendDataToFlow({ selectedSchool: this.selectedSchool });
                if (data.canContinue) {
                    flowManager.next();
                } else {
                    flowManager.complete();
                }
            } catch (e) {
                console.error(e);

                // start error flow
                flowManager.startFlow({
                    flowName: 'error',
                    flowSteps: [{ name: 'school-save-failed', disableBack: true }],
                });
            } finally {
                closeLoader('main');
            }
        },

        async shareMobile() {
            try {
                const shareData = {
                    text: this.$root.dataset.whatsappText,
                };
                await navigator.share(shareData);
            } catch (err) {
                console.warn(err);
            }
        },

        doSearch({ name, region, province, city, pageSize, page }) {
            // complete search
            const result = engine.search({
                query: name,
                sort: 'itemName_asc',
                filters: {
                    region: region ? [region] : [],
                    province: province ? [province] : [],
                    city: city ? [city] : [],
                },
                per_page: pageSize,
                page: page,
            });
            return { items: result.data.items || [], pagination: result.pagination };
        },

        aggregation(key, { region, province, city } = {}) {
            const { data } = engine.search({
                per_page: 100,
                filters: {
                    region: region ? [region] : [],
                    province: province ? [province] : [],
                    city: city ? [city] : [],
                },
            });
            return this._convertBuckets(data.aggregations[key]?.buckets || []);
        },

        _convertBuckets(buckets) {
            return [{ value: '', label: '' }, ...buckets.map((item) => ({ label: item.key, value: item.key }))];
        },
    };
}
