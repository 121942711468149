import { apiProvider } from '../../../../../../../../libs/api-provider';
import { userManager } from '../../../../../../../../libs/user-service';

export default function partialxConadDaysBanner() {
    return {
        enabledForUser: false,
        conadDaysCollectionCode: '', // provided by model
        creditsAccumulationThreshold: 0, // provided by api call
        couponValue: 0, // provided by api call
        spendingThreshold: 0, // provided by api call
        phase: '', // provided by api call
        title: '',
        text: '',
        buttonLink: '',
        buttonLabel: '',
        init() {
            this.conadDaysCollectionCode = this.$root.dataset?.conadDaysCollectionCode || '';
            if (this.conadDaysCollectionCode) this._loadConadDaysBannerContents();
        },
        // internal function
        async _loadConadDaysBannerContents() {
            const user = await userManager.getUser();
            if (!user || !user?.utenzaId) return;

            const data = await apiProvider.checkUserClusterForCollection({
                collectionCode: this.conadDaysCollectionCode,
                utenzaId: user?.utenzaId,
            });

            if (data?.userEnabledToConadDaysCollection) {
                this.creditsAccumulationThreshold = data?.clusterConfig?.creditsAccumulationThreshold;
                this.couponValue = data?.clusterConfig?.couponValue;
                this.spendingThreshold = data?.clusterConfig?.spendingThreshold;
                this.phase = data?.clusterConfig?.phase;

                if (this.creditsAccumulationThreshold <= 0) return;
                if (this.couponValue <= 0) return;
                if (!data?.clusterContents) return;

                this.title = data.clusterContents?.entryPointBannerTitle;
                this.text = data.clusterContents?.entryPointBannerText;
                this.buttonLink = data.clusterContents?.entryPointBannerButtonLink;
                this.buttonLabel = data.clusterContents?.entryPointBannerButtonLabel;
                this.enabledForUser = true;
            }
        },
        // main component functions
        fillPlaceholdersDirect(content) {
            content = content.replaceAll('{creditsAccumulationThreshold}', this.creditsAccumulationThreshold);
            content = content.replaceAll('{couponValue}', this.couponValue);
            content = content.replaceAll('{spendingThreshold}', this.spendingThreshold);
            return content;
        },
    };

    /*return {
        initialized: false,
        activeTab: 'credits',
        creditsAchievedCards: [],
        creditsAccumulationThreshold: 0, // provided by model
        couponValue: 0, // provided by model
        spendingThreshold: 0, // provided by model
        userCredits: 0, // provided by model
        totalCouponsNumber: 0, // calculated
        totalCouponsGain: 0, // calculated
        creditsToNextCoupon: 0, // calculated
        init() {
            if (!this.$root.dataset?.creditsAccumulationThreshold) return;
            if (!this.$root.dataset?.couponValue) return;

            this.creditsAccumulationThreshold = Number(this.$root.dataset?.creditsAccumulationThreshold);
            this.couponValue = Number(this.$root.dataset?.couponValue);
            this.spendingThreshold = Number(this.$root.dataset?.spendingThreshold);
            this.userCredits = Number(this.$root.dataset?.userCredits || 0);

            if (this.creditsAccumulationThreshold <= 0) return;
            if (this.couponValue <= 0) return;

            this.totalCouponsNumber = Math.trunc(this.userCredits / this.creditsAccumulationThreshold);
            this.totalCouponsGain = this.totalCouponsNumber * this.couponValue;
            this.creditsToNextCoupon =
                this.creditsAccumulationThreshold - (this.userCredits % this.creditsAccumulationThreshold);

            this._loadCreditsAchieved();
            this.initialized = true;
        },
        _singolarize(phrase, singolar = false) {
            if (!phrase) return phrase;
            // 'buoni spesa' | 'buono spesa'
            phrase = phrase.replaceAll(/([Bb]{1}uon)i( [Ss]{1}pesa)/g, `$1${singolar ? 'o' : 'i'}$2`);
            // 'crediti accumulati' | 'credito accumulato'
            phrase = phrase.replaceAll(
                /([Cc]{1}redit)i( [Aa]{1}ccumulat)i/g,
                `$1${singolar ? 'o' : 'i'}$2${singolar ? 'o' : 'i'}`,
            );
            // 'crediti' | 'credito'
            phrase = phrase.replaceAll(/([Cc]{1}redit)i/g, `$1${singolar ? 'o' : 'i'}`);
            return phrase;
        },
        // main component functions
        fillPlaceholders(singolarize = false, singolar = false) {
            if (!this.$el?.innerHTML) return;
            let content = this.$el.innerHTML;
            content = content.replaceAll('{creditsAccumulationThreshold}', this.creditsAccumulationThreshold);
            content = content.replaceAll('{couponValue}', this.couponValue);
            content = content.replaceAll('{spendingThreshold}', this.spendingThreshold);
            content = content.replaceAll('{userCredits}', this.userCredits);
            content = content.replaceAll('{totalCouponsNumber}', this.totalCouponsNumber);
            content = content.replaceAll('{totalCouponsGain}', this.totalCouponsGain);
            content = content.replaceAll('{creditsToNextCoupon}', this.creditsToNextCoupon);
            if (singolarize) content = this._singolarize(content, singolar);
            return content;
        },
        singolarization(singolar = false) {
            if (!this.$el?.innerHTML) return;
            return this._singolarize(this.$el.innerHTML, singolar);
        },
    };*/
}
