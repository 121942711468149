import { flowManager } from '../../../../../../libs/flow-manager';
import './style.scss';

export default function thankYouDonateBollini() {
    return {
        thankTxt: '',
        init() {
            this.thankTxt = this.$root.dataset.thankYouText;
            this.plural = this.$root.dataset.pluralName;
            this.singular = this.$root.dataset.singularName;
        },
        confirm() {
            flowManager.complete();
        },
        open() {
            const data = flowManager.getDataFromFlow();
            const quantity = data.numeroPunti;

            let quantityText = `${quantity} ${this.singular}`;
            if (quantity > 1) {
                quantityText = `${quantity} ${this.plural}`;
            }

            this.thankTxt = this.$root.dataset.thankYouText
                ? this.$root.dataset.thankYouText.replace('{0}', quantityText).replace('{1}', data?.codiceCartaTarget)
                : '';
        },
        close() {},
        cancel() {
            flowManager.complete();
        },
    };
}
