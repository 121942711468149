import { apiProvider } from '../../../../../../libs/api-provider';
import WizardComponent from '../../../../../../libs/components/wizard-component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { storeManager } from '../../../../../../libs/store-manager';
import { FUNNEL_NAME, FUNNEL_STEP, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { checkOriginEcommerce, getCallback } from '../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';

export default class SelectRegistration extends WizardComponent {
    constructor(name, root) {
        super(name, root);

        this.continue = this._dEl('continue');
        this.socialButtons = Array.from(this._dEl('socialButton', true));

        this._checkOriginEcommerce();
        this._addEventListeners();


    }

    /* override */
    open() {
        super.open();

        // Check if go to step 2
        const urlParams = new URLSearchParams(window.location.search);
        const step = urlParams.get('step');
        const goToStep2 = step == '2';

        // load ecommerce data, and track
        if (this.originEcommerce) {
            this.typeOfService = window.accessInfo.typeOfService;
            if (this.typeOfService) {
                storeManager.emit('ecRegistrationData', { typeOfService: this.typeOfService }, true);
            }
            this.pointOfServiceId = window.accessInfo.pointOfServiceId;
            if (this.pointOfServiceId) {
                storeManager.emit('ecRegistrationData', { pointOfServiceId: this.pointOfServiceId }, true);
            }
            this.cooperativeId = window.accessInfo.cooperativeId;
            if (this.cooperativeId) {
                storeManager.emit('ecRegistrationData', { cooperativeId: this.cooperativeId }, true);
            }
            this.userInfo = null;
            if (this.pointOfServiceId) {
                this.userInfo = {
                    coop: this.cooperativeId,
                    store: this.pointOfServiceId,
                };
                storeManager.emit('ecRegistrationData', { userInfo: this.userInfo }, true);
            }
            if (!goToStep2) {
                trackEvent(
                    TRACKABLE_EVENT.pageview,
                    FUNNEL_NAME.registration,
                    FUNNEL_STEP.registrationModeChoice,
                    this.typeOfService,
                    null,
                    this.userInfo
                );
            }
        } else {
            if (!goToStep2) {
                trackEvent(TRACKABLE_EVENT.pageview, FUNNEL_NAME.registration, FUNNEL_STEP.registrationModeChoice);
            }
        }

        if (goToStep2) {
            /* Next wizard, insert-card */
            this.continue.click();
        }
        this.root.classList.remove('opacity-0');
    }

    _addEventListeners() {
        this.continue.addEventListener('click', async (event) => {
            event.preventDefault();

            /* Tracking */
            if (this.originEcommerce && this.typeOfService && this.userInfo) {
                trackEvent(
                    TRACKABLE_EVENT.emailRegistration,
                    FUNNEL_NAME.registration,
                    FUNNEL_STEP.registrationWithEmail,
                    this.typeOfService,
                    null,
                    this.userInfo
                );
            } else {
                trackEvent(
                    TRACKABLE_EVENT.emailRegistration,
                    FUNNEL_NAME.registration,
                    FUNNEL_STEP.registrationWithEmail
                );
            }

            /* if point of service is defined get IT */
            if (this.pointOfServiceId) {
                const selectedPdv = await this._getPointOfServiceFromIS(this.pointOfServiceId);
                if (selectedPdv) {
                    storeManager.emit('selectedPdv', selectedPdv);
                }
            }

            /* Next wizard, insert-card */
            flowManager.next('insert-card');
        });


        this.socialButtons.forEach((item) => {
            const socialType = item.dataset.type;
            item.addEventListener('click', (event) => {
                event.preventDefault();
                this._trackSocialRegistration(socialType);
                this._sendSocialLogin(socialType);
            });
        });
    }

    _trackSocialRegistration(socialType) {
        let funnelStep;
        switch (socialType) {
            case 'facebook':
                funnelStep = FUNNEL_STEP.registrationWithFaceboook;
                break;
            case 'google':
                funnelStep = FUNNEL_STEP.registrationWithGoogle;
                break;
            case 'apple':
                funnelStep = FUNNEL_STEP.registrationWithApple;
                break;
            default:
                break;
        }
        if (!funnelStep) return;

        /* Tracking */
        if (this.originEcommerce && this.typeOfService && this.pointOfServiceId && this.userInfo) {
            trackEvent(
                TRACKABLE_EVENT.socialRegistration,
                FUNNEL_NAME.registration,
                funnelStep,
                this.typeOfService,
                null,
                this.userInfo
            );
        } else {
            trackEvent(TRACKABLE_EVENT.socialRegistration, FUNNEL_NAME.registration, funnelStep);
        }
    }

    _checkOriginEcommerce() {
        this.originEcommerce = checkOriginEcommerce();
        if (this.originEcommerce) {
            storeManager.emit('ecRegistrationData', { originEcommerce: true });
        }
    }

    async _getPointOfServiceFromIS(anacanId) {
        try {
            return await apiProvider.getPointOfServiceByAnacanId({ anacanId: anacanId });
        } catch (error) {
            console.warn(`Cannot get pdv ${anacanId}`, error);
        }
    }

    async _sendSocialLogin(socialType) {
        const data = {
            socialType,
            cb: getCallback(),
        };

        try {
            openLoader('main');
            const result = await apiProvider.socialLogin(data);
            window.location = result.redirectUrl; //TODO: Handle different user state
        } catch (error) {
            this.emailObj.setState('error');
            this.passwObj.setState('error');
            const message = error && error.frontendMessage ? error.frontendMessage : this.root.dataset.genericError;
            this.error.innerHTML = message;
            this.error.classList.remove(this._elMod('error', 'hidden'));
            closeLoader('main');
        }
    }
}


