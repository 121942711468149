import Component from '../../../../../../libs/components/component';
import { storeManager } from '../../../../../../libs/store-manager';
import { getTrackingManager } from '../../../../../../libs/tracking-manager';
import { flowManager } from '../../../../../../libs/flow-manager';
import { userManager } from '../../../../../../libs/user-service';
import { getCookieByName, writeCookieWithName } from '../../../../../../libs/utils';

import './style.scss';

export default class Page extends Component {
    constructor(name, root) {
        super(name, root);
        this.trackingManager = getTrackingManager();
        this.body = document.body;
        this.blockCounter = 0;

        this.coopPages = window.coopPages || [];
        const coopPagesRegex = window.coopPagesRegex || [];
        this.coopPagesRegex = coopPagesRegex.map((item) => new RegExp(item));

        this._addEventListeners();
        this._checkUserPrivacy();
        this._addStoreListeners();
    }

    _addEventListeners() {
        ////////////////////////////////////////
        ///////////////////////////////////////
        //////////////// TRACKING INTERCEPTOR
        ////////////////////////////////////////
        this._addListener(
            'click',
            (event) => {
                const linkEl = event.target.closest('[data-link-tracking]');
                if (!linkEl) return;
                const options = JSON.parse(linkEl.dataset.linkTracking);
                this.trackingManager.trackLink(linkEl, options);
            },
            this.root,
            { capture: true }
        );

        ////////////////////////////////////////
        ///////////////////////////////////////
        //////////////// CLICK INTERCEPTOR
        ////////////////////////////////////////
        this._addListener(
            'click',
            (event) => {
                const target = event.target.closest('a[href]');
                if (!target) return;

                //////////////////////// CHECK COOP
                if (this._coopLink(event, target)) {
                    return;
                }
            },
            this.root
        );
    }

    async _checkUserPrivacy() {
        await storeManager.waitInit();
        const user = storeManager.get('userData');
        if (!user) return;

        let requirePrivacyConsents = false;
        if (user.cardistaAnteCPlus == null || user.cardistaAnteCPlus != 'S') {
            requirePrivacyConsents = !user.privacy1 && !user.privacy2 && !user.privacy3;
        }

        if (requirePrivacyConsents) {
            flowManager.startFlow({
                flowName: 'requirePrivacyConsents',
                flowSteps: [{ name: 'privacy-consents' }],
            });
        }
    }

    _addStoreListeners() {
        this._addStoreListener('currentPage', () => {
            // check cardistaAnteCPlus popup
            this._cardistaAnteCPlusPopup();
        });
    }

    async _cardistaAnteCPlusPopup() {
        let user = await userManager.getUser();
        if (!user) return;

        if (user.cardistaAnteCPlus != 'S') return;

        let cookieCardistaAnteCPlus = getCookieByName('ecCardistaAnteCPlusPopup');
        if (cookieCardistaAnteCPlus) return;

        // get conad domain
        let domainParts = location.hostname.split('.');
        let domain = location.hostname;
        if (domainParts.length >= 2) {
            domain = '.' + domainParts.slice(-2).join('.');
        }

        // write cookie
        let currentDate = new Date();
        let expiringDate = new Date(currentDate.getTime() + 604800000);
        writeCookieWithName('ecCardistaAnteCPlusPopup', 'true', domain, expiringDate.toUTCString());

        flowManager.startFlow({ flowName: 'cardista-ante-c-plus', flowSteps: [{ name: 'cardista-ante-c-plus-popup' }] });
    }

    _coopLink(event, target) {
        let link = null;
        const href = target.getAttribute('href');
        if (!href) return false;

        // check stanard coop pages
        for (const page of this.coopPages) {
            if (page == href) {
                link = target;
                break;
            }
        }
        // check regex
        for (const regex of this.coopPagesRegex) {
            if (href.match(regex)) {
                link = target;
                break;
            }
        }
        if (!link) return false;

        // redirect to coop
        this._redirectToCoop(link);
        return true;
    }

    _redirectToCoop(link) {
        const urlParams = new URLSearchParams(window.location.search);
        const userData = storeManager.get('userData');
        const selectedPdv = storeManager.get('selectedPdv');
        let coop = urlParams.get('coop') || userData?.newMappedCooperative || 'cnd';
        if (coop.toLowerCase() == 'cnd' && selectedPdv?.codiceCooperativa) {
            coop = selectedPdv.codiceCooperativa;
        }

        const url = new URL(link.href, window.origin);
        url.searchParams.set('coop', coop.toLowerCase());
        link.href = url.href;
    }

    toggleBlockPageScroll() {
        const hasBlock = this.body.classList.contains('block-scroll');
        if (hasBlock) this.removeBlockScroll();
        else this.setBlockScroll();
    }

    setBlockScroll() {
        if (this.blockCounter > 0) {
            this.blockCounter++;
            return;
        }
        const bodyWidth = this.body.clientWidth;
        this.body.classList.add('block-scroll');
        const margin = this.body.clientWidth - bodyWidth;
        //set body margin
        this.body.style.marginRight = `${margin}px`;
        //set fixed margin
        const fixedEls = this.root.querySelectorAll('.is-fixed');
        for (const fix of fixedEls) {
            fix.style.marginRight = `${margin}px`;
        }
        this.blockCounter++;
    }

    removeBlockScroll() {
        if (this.blockCounter <= 0) return;
        this.blockCounter--;
        //remove block scroll only if block counter = 0 so there is only a 1 modal/popup open on page
        if (this.blockCounter > 0) return;

        this.body.classList.remove('block-scroll');
        //set body margin
        this.body.style.marginRight = 0;
        //set fixed margin
        const fixedEls = this.root.querySelectorAll('.is-fixed');
        for (const fix of fixedEls) {
            fix.style.marginRight = 0;
        }
    }
}
