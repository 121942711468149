import { apiProvider } from '../../../../../../libs/api-provider';
import Component from '../../../../../../libs/components/component';
import { flowManager } from '../../../../../../libs/flow-manager';
import { FUNNEL_NAME, TRACKABLE_EVENT, trackEvent } from '../../../../../../libs/tracking-manager-old';
import { userManager } from '../../../../../../libs/user-service';
import { openLoader } from '../../../templates/mt11-loader/script';

import './style.scss';

export default class Menu extends Component {
    constructor(name, root) {
        super(name, root);

        this.links = Array.from(this._dEl('link', true));
        this.exitLink = this._dEl('exitLink');

        this._addEventListeners();
        this._init();
    }

    _addEventListeners() {
        this.root.addEventListener('click', (event) => {
            const btn = event.target.closest(this._el('navSectionBtn', true));
            if (!btn) return;
            const navSection = btn.closest(this._el('navSection', true));
            if (!navSection) return;
            const content = navSection.querySelector(this._el('navSectionContent', true));
            if (!content) return;
            this._isDropdownOpen(btn)
                ? this._closeDropdown(btn, content, navSection)
                : this._openDropdown(btn, content, navSection);
        });
        this.links.forEach((link) => {
            link.addEventListener('click', (event) => {
                if (link.classList.contains('active')) {
                    event.preventDefault();
                    return;
                }

                trackEvent(TRACKABLE_EVENT.clickProfile, FUNNEL_NAME.personalMenu, link.innerText.trim());
                setTimeout(() => {
                    openLoader('main');
                }, 400);
            });
        });

        this.exitLink.addEventListener('click', (event) => {
            event.preventDefault();
            flowManager.startFlow({
                flowName: 'logout',
                flowSteps: [{ name: 'logout' }],
            });
        });
    }

    async _init() {
        const user = await userManager.getUser();

        //remove conad card links if not present
        if (!user || !user.conadCard) {
            const ciPlus = Array.from(this.root.querySelectorAll('[data-ci-plus=true]'));
            for (let ci of ciPlus) {
                ci.style.display = 'none';
            }
        }

        // remove all data-collection-code if not bimodal otherwise check collection code
        if (!user || !user.cartaFedelta) {
            const colCode = Array.from(this.root.querySelectorAll('[data-collection-code]'));
            for (let ci of colCode) {
                ci.style.display = 'none';
            }
        } else {
            const collectionPages = this.root.querySelectorAll('[data-collection-code]');
            for (const page of collectionPages) {
                const collectionCode = page.dataset.collectionCode;
                const balance = await apiProvider.getCachedUserBalance({
                    collectionCode,
                    fidelityCard: user.cartaFedelta,
                });

                if (!balance || balance.saldoCumulato <= 0) page.style.display = 'none';
            }
        }

        // remove Conad Days links if data-conad-days-collection-code does not match
        const conadDaysPages = this.root.querySelectorAll('[data-conad-days-collection-code]');
        for (const page of conadDaysPages) {
            if (!user) {
                page.style.display = 'none';
            } else {
                const conadDaysCollectionCode = page.dataset.conadDaysCollectionCode || '';
                const data = await apiProvider.checkUserClusterForCollection({
                    collectionCode: conadDaysCollectionCode,
                    utenzaId: user?.utenzaId,
                });

                if (!data?.userEnabledToConadDaysCollection) page.style.display = 'none';
            }
        }

        this.root.classList.add(this._mod('initialized'));
        this._setActivePage();
    }

    _isDropdownOpen(btn) {
        return btn?.classList.contains('open');
    }

    _openDropdown(btn, content, navSection) {
        if (content?.style) content.style.visibility = 'visible';
        btn?.classList.add('open');
        this._updateAriaAttributes(btn, content);
        navSection.classList.add('open');
    }

    _closeDropdown(btn, content, navSection) {
        btn?.classList.remove('open');
        this._updateAriaAttributes(btn, content);
        setTimeout(() => {
            if (content?.style) content.style.visibility = 'hidden';
        }, 300);
        navSection.classList.remove('open');
    }

    _updateAriaAttributes(btn, content) {
        const isOpen = this._isDropdownOpen(btn);
        btn?.setAttribute('aria-expanded', `${isOpen ? 'true' : 'false'}`);
        content?.setAttribute('aria-hidden', `${isOpen ? 'false' : 'true'}`);
    }

    _setActivePage() {
        const link = this.root.querySelector(`[data-page-path='${window.pagePath}']`);
        if (!link) return;

        link.classList.add('active');
        const navSection = link.closest(this._el('navSection', true));
        if (!navSection) return;
        const content = navSection.querySelector(this._el('navSectionContent', true));
        if (!content) return;
        const btn = navSection.querySelector(this._el('navSectionBtn', true));
        if (!btn) return;

        this._openDropdown(btn, content, navSection);
    }
}
