import { apiProvider } from '../../../../../../libs/api-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { register } from '../../../../../../libs/register';
import { delay } from '../../../../../../libs/utils';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';

export default function donateToCommunity() {
    return {
        school: undefined,
        balance: 0,
        selected: 0,
        isValidToTransferAll: true,
        init() {
            this.inputCl = register.getClass(this.$refs.buoni);
        },
        open() {
            const data = flowManager.getDataFromFlow();
            this.school = data.selectedSchool;
            this.balance = data.balance;
            this.settings = data.settings;
            this.uuid = data.uuid;
            this.inputCl.reset();
            this.inputCl.setPermanentText('/' + (this.balance || '0'));
            this.inputCl.setValidationContext({
                balance: data.balance,
            });
        },
        close() {},
        cancel() {
            flowManager.complete();
        },
        changed() {
            const value = this.inputCl.getValue() || '0';
            this.selected = parseInt(value);
            this.isValidToTransferAll = this.selected != this.balance;
        },
        populateAll() {
            this.inputCl.reset();
            this.inputCl.setValue(this.balance);
        },
        confirmDonate(toDonate) {
            flowManager.startFlow({
                flowName: 'confirm-donate-buoni',
                flowSteps: [{ name: 'buoni-donate-confirm', disableBack: true }],
                initialData: {
                    target: this.school.itemName,
                    quantity: toDonate,
                },
                onComplete: (name, data) => {
                    if (!data.ok) return;
                    this.finishDonate(toDonate);
                },
            });
        },
        changeSchool() {
            flowManager.back();
        },

        async finishDonate(toDonate) {
            try {
                openLoader('main');

                await apiProvider.donateBuoni({
                    requestId: this.uuid,
                    initiativeCode: this.settings.initiativeCode,
                    edition: this.settings.editionCode,
                    codiceCollection: this.settings.collectionCode,
                    amount: toDonate,
                    beneficiary: this.school.itemCode,
                });

                await delay(500);
                flowManager.appendDataToFlow({ toDonate });
                flowManager.next();
            } catch (e) {
                console.warn(e);
                this._showErrorPopup();
            } finally {
                closeLoader('main');
            }
        },
        _showErrorPopup(_error) {
            // check error and open the correct popup ( after integration )
            flowManager.startFlow({
                flowName: 'buoni-donate-generic-error',
                flowSteps: [{ name: 'buoni-donate-generic-error', disableBack: true }],
            });

            // if transaction error, then the flow need to be closed before opening the buoni donate transaction erro.
            // flowManager.complete();
            // flowManager.startFlow({
            //     flowName: 'buoni-donate-transaction-error',
            //     flowSteps: [{ name: 'buoni-donate-transaction-error', disableBack: true }],
            // });
        },
    };
}
