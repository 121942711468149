import { flowManager } from '../../../../../../libs/flow-manager';
import './style.scss';

export default function cardistaAnteCPlus() {
    return {
        init() {},
        before() {},
        open() {},
        close() {},
        cancel() {
            flowManager.complete();
        }

    };
}