import { flowManager } from '../../../../../../libs/flow-manager';
import { delay } from '../../../../../../libs/utils';

export default function thankYouCommunity() {
    return {
        textTpl: undefined,
        school: undefined,
        toDonate: undefined,
        toDonateText: '',
        shareLoading: false,
        init() {
            this.textTpl = this.$refs.text.innerHTML;
            this.plural = this.$root.dataset.pluralName;
            this.singular = this.$root.dataset.singularName;
        },
        before() {
            const data = flowManager.getDataFromFlow();
            this.school = data.selectedSchool;
            this.toDonate = data.toDonate;

            this.toDonateText = `${this.toDonate} ${this.singular}`;
            if (this.toDonate > 1) {
                this.toDonateText = `${this.toDonate} ${this.plural}`;
            }

            this.$refs.text.innerHTML = this.textTpl
                .replace('{0}', this.toDonateText)
                .replace('{1}', `${this.school.itemName}<br/>${this.school.address}`);
        },
        open() {},
        close() {},
        cancel() {
            flowManager.complete();
        },
        async shareOnSocial() {
            try {
                this.shareLoading = true;
                const tpl = this.$refs.shareTemplate;
                const elm = tpl.firstElementChild;
                const doToBlob = (await import('modern-screenshot')).domToBlob;
                const blb = await doToBlob(elm, {
                    width: 500,
                    height: 600,
                    backgroundColor: '#ffffff00',
                    type: 'image/png',
                });

                const file = new File([blb], 'share-image.png', { type: blb.type });

                const shareData = {
                    files: [file],
                };
                delay(500);
                if (navigator.canShare(shareData)) {
                    await navigator.share(shareData);
                }
            } catch (err) {
                console.warn(err);
            } finally {
                this.shareLoading = false;
            }
        },
    };
}
