import { flowManager } from '../../../../../../libs/flow-manager';
import { base64url_encode } from '../../../../../../libs/utils';

export default function genericError() {
    return {
        initialTitle: '',
        initialText: '',
        init() {
            this.initialTitle = this.$refs.title.innerHTML;
            this.initialText = this.$refs.text.innerHTML;
        },
        before() {
            const data = flowManager.getDataFromFlow();
            // set title
            if (data?.errorTitle) {
                this.$refs.title.innerHTML = data?.errorTitle;
            }
            // set message
            if (data?.errorMessage) {
                this.$refs.text.innerHTML = data.errorMessage;
            }
            // set open ticket params
            if (this.$refs.openTicketLink) {
                const url = new URL(this.$refs.openTicketLink.href);
                url.searchParams.append('ccRequestType', 'Richiesta di supporto tecnico');
                url.searchParams.append('codiceErrore', 'BOOOO');
                url.searchParams.append('descrizioneErrore', base64url_encode('AIUTO!!!'));
                this.$refs.openTicketLink.setAttribute('href', url.toString());
            }
        },
        open() {},
        close() {
            this.$refs.text.innerHTML = this.initialText;
            this.$refs.title.innerHTML = this.initialTitle;
        },
        cancel() {
            flowManager.complete();
        },

        openTicket() {
            flowManager.complete();
            window.location.hre;
        },
    };
}
