import { apiProvider } from '../../../../../../libs/api-provider';
import ModalComponent from '../../../../../../libs/components/modal-component';
import { dictionary } from '../../../../../../libs/dictionary-provider';
import { flowManager } from '../../../../../../libs/flow-manager';
import { informativaManager } from '../../../../../../libs/informativa-manager';
import { register } from '../../../../../../libs/register';
import { storeManager } from '../../../../../../libs/store-manager';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script';
import './style.scss';

export default class ConfirmLoginModal extends ModalComponent {
    constructor(name, root) {
        super(name, root);

        this.error = this._dEl('error');

        this.form = this._dEl('form');
        this.emailText = this._dEl('emailText');
        this.password = this._dEl('password');
        this.passwObj = register.getClass(this.password);

        this.recoverPwdText = this._dEl('recoverPwdText');
        this.recoverPwdLink = JSON.parse(this.recoverPwdText.dataset.recoverPwd);

        this.bottomText = this._dEl('bottomText');
        this.wrongEmail = this._dEl('wrongEmail');
        this.submit = this._dEl('submit');

        this._addEventListeners();
        this._addStoreListeners();
    }

    _onOpen() {
        this.email = storeManager.get('userData').email;
        this.coop = storeManager.get('userData').newMappedCooperative;
        this._initDynamicTexts();
    }

    _addEventListeners() {
        /*this.root.addEventListener('ecInputChanged', () => {
            this._checkShowSubmit();
        });*/
        this.wrongEmail?.addEventListener('click', async (event) => {
            event.preventDefault();
            try {
                openLoader('main');
                await apiProvider.logout();
                const url = new URL(this.wrongEmail.href);
                url.searchParams.set('cb', encodeURIComponent(window.location.href));
                window.location = url.href;
            } catch (error) {
                console.warn(error);
                closeLoader('main');
            }
        });

        this.form.addEventListener('submit', (event) => {
            event.preventDefault();

            if (!this.form.checkValidity()) {
                const first = this.form.querySelector('input:invalid');
                if (first) {
                    setTimeout(() => {
                        first.scrollIntoView({
                            block: 'center',
                        });
                    }, 600);
                }
                return;
            }

            //this._checkShowSubmit();
            if (!this.error.classList.contains(this._elMod('error', 'hidden'))) {
                this.error.classList.add(this._elMod('error', 'hidden'));
                this.error.innerHTML = '';
            }
            this.passwObj.setState('');
            this._doLogin();
        });
    }

    _addStoreListeners() {
        //intercept flow options and get submitEl if defined
        this._addStoreListener(/flow\/[^/]+$/g, (path, flow) => {
            this.submitEl = flow?.options?.submitEl || null;
            this.calleeId = flow?.options?.calleeId || null;
        });
    }

    async _sendLogin() {
        if (!this.passwObj.isValid()) {
            console.log('Password not filled in');
            return;
        }

        const userData = storeManager.get('userData');
        const data = {
            email: userData.email,
            password: this.passwObj.getValue(),
            cb: window.location.href
        };

        try {
            openLoader('main');
            const result = await apiProvider.confirmLogin(data);
            if (!result.cbValid) {
                window.location.href = '/';
            }
            closeLoader('main');
            flowManager.appendDataToFlow({ ok: true });
            flowManager.complete();
            storeManager.emit('mm10LoginConfirmed', {
                confirmed: !!result,
                submitEl: this.submitEl,
                calleeId: this.calleeId,
            });
        } catch (error) {
            this.passwObj.setState('error');
            let message =
                error?.codice == 'IS_ERR:CREDENZIALI_NON_VALIDE' ? dictionary.getFEMessage('failedConfirmLogin') : null;
            if (!message)
                message = error && error.frontendMessage ? error.frontendMessage : this.root.dataset.genericError;
            this.error.innerHtml = '';
            this.error.append(message);
            this.error.classList.remove(this._elMod('error', 'hidden'));
            closeLoader('main');
        }
    }

    async _doLogin() {
        await this._sendLogin();
    }

    async _initDynamicTexts() {
        const userData = storeManager.get('userData');
        const pdv = storeManager.get('selectedPdv');

        this._replacePlaceholderWithElement(
            this.recoverPwdText,
            '$recover-pwd',
            this.recoverPwdLink.label,
            'a',
            'recoverPwd',
            {
                href: this.recoverPwdLink.href,
            }
        );

        const result = await informativaManager.getInformativa(userData, pdv);
        if (!result) {
            console.warn(`Cannot set informativa for coop "${this.coop}", empty response returned`);
            this._replacePlaceholderWithElement(
                this.bottomText,
                '$privacy-policy',
                this.bottomText.dataset.privacyPolicyLabel,
                'span'
            );
        } else {
            this._replacePlaceholderWithElement(
                this.bottomText,
                '$privacy-policy',
                this.bottomText.dataset.privacyPolicyLabel,
                'a',
                null,
                {
                    href: result.linkPdfInformativa,
                    title: `informativa ${result.version}`,
                    target: '_blank',
                }
            );
        }
        this._replacePlaceholderWithElement(
            this.bottomText,
            '$cookie-policy',
            this.bottomText.dataset.cookiePolicyLabel,
            'a',
            null,
            {
                href: this.bottomText.dataset.cookiePolicy,
                target: '_blank',
            }
        );
    }

    // linkData: { href, title, target }
    _replacePlaceholderWithElement(container, placeholder, label, el, clazz = null, linkData = null) {
        try {
            const split = container.innerHTML.split(placeholder);
            if (split.length <= 1) return;
            const replacedEl = document.createElement(el);
            if (clazz) replacedEl.classList.add(`${this.getName()}__${clazz}`);
            if (el === 'a' && linkData && linkData.href) {
                replacedEl.setAttribute('href', linkData.href);
                if (linkData.title) replacedEl.setAttribute('title', linkData.title);
                if (linkData.target) replacedEl.setAttribute('target', linkData.target);
            }
            replacedEl.innerText = label;
            container.innerHTML = '';
            container.insertAdjacentHTML('beforeend', split[0]);
            container.append(replacedEl);
            container.insertAdjacentHTML('beforeend', split[1]);
        } catch (error) {
            console.warn(error);
            return;
        }
    }

    _disableEl(el, elName) {
        if (!el || !elName) return;
        if (!el.classList.contains(this._elMod(elName, 'disabled'))) el.classList.add(this._elMod(elName, 'disabled'));
    }
    
    _enableEl(el, elName) {
        if (!el || !elName) return;
        el.classList.remove(this._elMod(elName, 'disabled'));
    }

    /* override */
    _onCancel() {
        super._onCancel();
        flowManager.backOrComplete();
    }
}
