import { apiProvider } from '../../../../../../libs/api-provider.js';
import { flowManager } from '../../../../../../libs/flow-manager.js';
import { userManager } from '../../../../../../libs/user-service.js';
import { v4 } from 'uuid';

export default function buoniCommunity() {
    // non proxy data
    let schools = [];

    return {
        scuole: [],
        saldoBuoni: 0,
        saldoDonato: 0,
        preferred: undefined,
        movementsLoaded: false,
        movementsEmpty: false,
        balanceLoaded: false,
        settings: {
            initiativeCode: '',
            collectionCode: '',
            editionCode: '',
        },

        init() {
            this.settings.initiativeCode = this.$root.dataset.initiativeCode;
            this.settings.collectionCode = this.$root.dataset.collectionCode;
            this.settings.editionCode = this.$root.dataset.editionCode;
            this.start();
        },

        async start() {
            try {
                await this.loadSchools();
                await this.loadPreferredSchool();
                await this.loadBalance();
                await this.loadMyDonation();
            } catch (e) {
                console.warn(e);
            }
        },

        async loadSchools() {
            // get schools
            const resp = await apiProvider.getBuoniSchools();
            schools = resp?.data || [];
        },

        async loadBalance() {
            const user = await userManager.getUser();
            const data = {
                fidelityCard: user.cartaFedelta,
                collectionCode: this.settings.collectionCode,
            };

            const response = await apiProvider.getUserBalance(data);
            this.saldoBuoni = response.saldoCumulato - response.saldoSpeso;
            this.balanceLoaded = true;
        },

        /** Load preferred school */
        async loadPreferredSchool() {
            const response = await apiProvider.getBuoniPreferred();
            if (!response || !response.valore) return;

            const itemCode = response.valore;
            this.preferred = schools.find((item) => item.itemCode == itemCode);
        },

        /** Load donations */
        async loadMyDonation() {
            try {
                // reset
                this.movementsLoaded = false;
                this.movementsEmpty = false;
                this.scuole = [];

                // get from backend
                const response = await apiProvider.getBuoniMovements({
                    initiativeCodes: [this.settings.initiativeCode],
                });
                if (!response?.structuresCredits || !Array.isArray(response.structuresCredits)) return;

                // populate
                for (const structure of response.structuresCredits) {
                    const schoolDetails = schools.find((item) => item.itemCode == structure.structureId);
                    if (!schoolDetails) continue;

                    this.scuole.push({
                        ...schoolDetails,
                        tueDonazioniBuoni: structure.userCredits,
                        donazioniClienti: structure.communityCredits,
                    });
                }

                const totalUserCredits = response.totalUserCredits.filter(
                    (item) => item.sustainabilityInitiativeCode == this.settings.initiativeCode
                );
                if (totalUserCredits && totalUserCredits.length > 0) this.saldoDonato = totalUserCredits[0].credits;

                if (this.scuole.length <= 0) this.movementsEmpty = true;
            } catch (error) {
                console.error('Errore nella chiamata API:', error);
                this.movementsEmpty = true;
            } finally {
                this.movementsLoaded = true;
            }
        },

        get movements() {
            let result = [...this.scuole];
            const index = result.findIndex((a) => a.itemCode == this.preferred?.itemCode);
            if (index > -1) {
                const el = result.splice(index, 1)[0];
                result.unshift(el);
            }
            return result;
        },

        dona() {
            flowManager.startFlow({
                flowName: 'donate-selection',
                flowSteps: [
                    { name: 'search-community', disableBack: true },
                    { name: 'donate-to-community', disableBack: true, initialStep: this.preferred?.itemCode || false },
                    { name: 'thank-you-community', disableBack: true },
                ],
                initialData: {
                    canContinue: true, //permits to search to continue to next step
                    selectedSchool: this.preferred || undefined,
                    balance: this.saldoBuoni,
                    settings: this.settings,
                    uuid: v4(),
                },
                onComplete: (name, data) => {
                    if (data.selectedSchool) this.preferred = data.selectedSchool;
                    if (data.toDonate) {
                        this.loadBalance();
                        this.loadMyDonation();
                    } // refresh donations
                },
            });
        },

        changeCommunity() {
            flowManager.startFlow({
                flowName: 'change-community',
                flowSteps: [{ name: 'search-community', disableBack: true }],
                initialData: {
                    canContinue: false,
                },
                onComplete: (name, data) => {
                    if (!data.selectedSchool) return;
                    this.preferred = data.selectedSchool;
                },
            });
        },

        openRules() {
            // run flow manager
            flowManager.startFlow({
                flowName: 'rules-popup',
                flowSteps: [{ name: 'regolamenti-buoni' }],
            });
        },
    };
}
