///////////////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////
//////////////// STYLE
///////////////////////////////////////////////////
///////////////////////////////////////////////////
///////////////////////////////////////////////////
import './style/tailwind.css';
import './style/global.scss';
import './style/vendor.scss';

/////////////////////////////////// ALPINE
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import collapse from '@alpinejs/collapse';
import { cDialogOn } from './extensions/directives/cdialogon';

/////////////////////////////////// LIBRARIES SCRIPTS
import { register } from './libs/register';
import { flowManager } from './libs/flow-manager';
import { storeManager } from './libs/store-manager';
import { fieldValidator } from './libs/field-validator';

/////////////////////////////////// COMPONENTS SCRIPTS
import { registerAll } from './load-components';

/////////////////////////////////// LOAD
const initTools = () => {
    // init global tools
    fieldValidator.init();
    storeManager.checkInit(window.initialStore);

    // init alpine plugins
    Alpine.plugin(intersect);
    Alpine.plugin(collapse);
    Alpine.directive('cdialogon', cDialogOn);
};

const startRegister = () => {
    register.apply(document.body);
};

const startAlpine = () => {
    // start Alpine!!!!
    window.Alpine = Alpine;
    Alpine.start();
};

const startFlowManager = () => {
    flowManager.checkInit(window.initialFlow);
}

(async () => {
    const start = new Date();
    if (process.env.NODE_ENV === 'development') {
        console.log('[MYC] Starting dev-utils...');
        const devUtils = await import('./libs/dev-utils');
        await devUtils.default();
        console.log('[MYC] 🔧 Variables loaded');
    }
    console.log('[MYC] ⛵ Initializing tools... ', new Date().getTime() - start.getTime());
    initTools();
    console.log('[MYC] ⛵ Import components... ', new Date().getTime() - start.getTime());
    registerAll();
    console.log('[MYC] 🚂 Start Register... ', new Date().getTime() - start.getTime());
    startRegister();
    console.log('[MYC] 🚞 Start Alpine...', new Date().getTime() - start.getTime());
    startFlowManager();
    console.log('[MYC] 🍕 Start Flow Manager...', new Date().getTime() - start.getTime());
    startAlpine();
    console.log('[MYC] 🚀 Init Complete', new Date().getTime() - start.getTime());
})();
