import { apiProvider } from '../../../../../../libs/api-provider';
import { userManager } from '../../../../../../libs/user-service';
import { closeLoader, openLoader, isLoaderOpened } from '../../../templates/mt11-loader/script';
import './style.scss';

export default function buoniBolliniHub() {
    return {
        balance: {},
        isLoaded: {},
        numberOfCards: Number(this.$root.dataset.numberOfCards),
        async initCard(el) {
            const collectionCode = el.dataset.collectionCode;

            try {
                if (!isLoaderOpened('main')) openLoader('main');
                this.isLoaded[collectionCode] = false;

                const user = await userManager.getUser();
                const data = {
                    fidelityCard: user.cartaFedelta,
                    collectionCode,
                };
                const balance = await apiProvider.getUserBalance(data);


                this.balance[collectionCode] = balance.saldoCumulato - balance.saldoSpeso;

            } catch (error) {
                console.warn('Errore nella chiamata API:', error);
            } finally {
                this.isLoaded[collectionCode] = true;
                this._checkCloseLoader();
            }
        },
        _checkCloseLoader() {
            // close loader only if all the fetches are ended
            if (Object.keys(this.isLoaded).length == this.numberOfCards) {
                const keys = Object.keys(this.isLoaded);
                for (let i = 1; i <= this.numberOfCards; i++) {
                    if (this.isLoaded[keys[i-1]] != true) {
                        return;
                    }
                }
                closeLoader('main');
            }
        }
    };
}
