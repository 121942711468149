import Component from '../../../../../../libs/components/component';
import { closeLoader, openLoader } from '../../../templates/mt11-loader/script'; 
import { getApiProvider } from '../../../../../../libs/api-provider';

export default class Assicurazioni extends Component {
    constructor(name, root) {
        super(name, root);
        this.products;
        this.loaderEndpoint = this.root.dataset.endpoint;
        this.initLength = parseInt(this.root.dataset.initLength);

        this.downloadLength = parseInt(this.root.dataset.downloadLength);
        this.loadMore = this._dEl('loadMore');
        this.content = this._dEl('content');

        this.activeProductsContainer = this._dEl('activeProductsContainer');
        this.inactiveProductsContainer = this._dEl('inactiveProductsContainer');
        this.text = this._dEl('text');
        this.noProducts = this._dEl('noProducts');
        this.currentSize = 0;

        this.apiProvider = getApiProvider();
        this._init();
        this._addEventListeners();
    }

    _init() {
        openLoader('main');
        this._downloadProducts(this.currentSize, this.initLength);
    }

    async _downloadProducts(init, length) {
        const url = this.loaderEndpoint;
        const data = {
            init: init,
            length: length,
            currentSize: this.currentSize,
        };
        try {
            const responseContent = await this.apiProvider.loaderGet(url, data);
            this._appendProducts(responseContent);
        } catch (error) {
            console.error(error);
        } finally {
            closeLoader('main');
        }
    }

    _appendProducts(products) {
        /* parse new products */
        const parser = new DOMParser();
        const doc = parser.parseFromString(products, 'text/html');
        const newActiveProducts = doc.querySelectorAll('.mf20-assicurazioni__activeProduct');
        const newInactiveProducts = doc.querySelectorAll('.mf20-assicurazioni__inactiveProduct');
        const newProducts = doc.querySelectorAll('.mf20-assicurazioni__product');

        const countProducts = doc.querySelector(this._el('loader', true)).dataset.countProducts;

        let classList_loadMore = this.loadMore.classList;
        let classList_content = this.content.classList;
        let classList_noProducts = this.noProducts.classList;
        let classList_text = this.text.classList;

        /* hide/show loadMore */
        if (this.currentSize != 0 && newProducts.length < this.downloadLength) {
            /* case no more products to download next */
            classList_loadMore.add('invisible');
        } else {
            /* case possibly more products to download next */
            classList_loadMore.remove('invisible');
        }

        /* hide/show products */
        if (this.currentSize + newProducts.length <= 0) {
            /* case no products */
            classList_content.add('hidden');
            classList_noProducts.remove('hidden');
            classList_text.add('hidden');
            classList_loadMore.add('invisible');
        } else {
            /* case products */
            classList_content.remove('hidden');
            classList_text.remove('hidden');
            classList_noProducts.add('hidden');

            /* append new products */
            if (newActiveProducts && newActiveProducts.length >= 1) {
                this.activeProductsContainer.classList.remove('hidden');
                this.activeProductsContainer.append(...newActiveProducts);
            }
            if (newInactiveProducts && newInactiveProducts.length >= 1) {
                this.inactiveProductsContainer.classList.remove('hidden');
                this.inactiveProductsContainer.append(...newInactiveProducts);
            }
            /* update current size */
            this.currentSize += newProducts.length;
            /* hide/show loadMore */
            if (this.currentSize >= countProducts) {
                classList_loadMore.add('invisible');
            }
        }
    }

    _emptyProducts(container) {
        if (!container || container.objReference || !container.hasChildNodes()) return;
        for (let i = container.childElementCount; i > 0; i--) {
            container.removeChild(container.children[i - 1]);
        }
    }

    _addEventListeners() {
        this.loadMore.addEventListener('click', async () => {
            openLoader('main');
            const lastFocusedProduct = this.content.querySelector(`${this._el('product', true)}:last-child`);
            await this._downloadProducts(this.currentSize, this.downloadLength);
            setTimeout(() => {
                lastFocusedProduct.focus();
            }, 500);
        });
    }
}
