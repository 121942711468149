import Popup from '../../repository/apps/conad-myconad/templates/mt4-popup/script';
import Component from './component';
import { storeManager } from '../store-manager';

export default class PopupComponent extends Component {
    constructor(name, root) {
        super(name, root);
        this.popup = Popup.getCurrentPopup(this.root);
        this.popupName = this.popup.getPopupName();

        this._initModalEvents();
        this._initSteps();
    }

    _initModalEvents() {
        /* modal events */
        this.popup.getRoot().addEventListener('ecPopupOpen', () => {
            this._onOpen();
        });
        this.popup.getRoot().addEventListener('ecPopupBefore', () => {
            this._onBeforeOpen();
        });
        this.popup.getRoot().addEventListener('ecPopupClose', () => {
            this._onClose();
        });
        this.popup.getRoot().addEventListener('ecPopupCancel', () => {
            this._onCancel();
        });
    }

    _initSteps() {
        storeManager.on(/flow\/[^/]+\/activeStep/g, (path, activeStep) => {
            if (activeStep.el != this.popup.getRoot()) {
                if (this.popup.isOpen()) this.popup.close();
            } else {
                if (!this.popup.isOpen()) this.popup.open();
            }
        });
    }

    _onOpen() {
        //focus content
        this.popup?.content?.focus();
    }

    _onClose() {}

    _onCancel() {}

    _onBeforeOpen() {}
}
