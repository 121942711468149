import { apiProvider } from './api-provider';
import { storeManager } from './store-manager';

class FieldValidator {
    constructor() {}

    #MONTH_DAYS = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; // List of days of a month (assume there is no leap year by default)
    #DEFAULT_AGE_LIMIT = 18;
    #MILLISECONDS_TO_YEARS = 1000 * 60 * 60 * 24 * 365;
    #FEBRUARY_29 = 29;
    #BASE_10 = 10;
    #GMAPS_ADDRESS_TYPES = 'types';
    #GMAPS_ADDRESS_ADDRESS_COMPONENTS = 'address_components';
    #GMAPS_ADDRESS_SHORT_NAME = 'short_name';
    #GMAPS_ADDRESS_STREET_ADDRESS = 'street_address';
    #GMAPS_ADDRESS_ROUTE = 'route';
    #validationConfigs = [];

    /*******************************************************************************************************************
     * CUSTOM VALIDATION FUNCTIONS
     * Make sure that the function names in the validation config have a corresponding validation function below.
     * Each function must take at least the "value" parameter, optionally the "ctx" parameter and additional args.
     * The "ctx" parameter is an object with free properties as needed.
     * Make sure that the functions always return true|false.
     *******************************************************************************************************************/
    #validationsFn = {
        dataNascita: (value, ctx) => {
            return this.#dataNascita(value, ctx);
        },
        esisteDataNascitaUtente: () => {
            return this.#esisteDataNascitaUtente();
        },
        dataNascitaFiglio: (value) => {
            return this.#dataNascitaFiglio(value);
        },
        pswRipetuta: (value, ctx) => {
            return this.#pswRipetuta(value, ctx);
        },
        indirizzoAutocomplete: (value, ctx) => {
            return this.#indirizzoAutocomplete(value, ctx);
        },
        indirizzoAutocompleteConVia: (value, ctx) => {
            return this.#indirizzoAutocompleteConVia(value, ctx);
        },
        indirizzoAutocompleteConCivico: (value, ctx) => {
            return this.#indirizzoAutocompleteConCivico(value, ctx);
        },
        bolliniMax: (value, ctx) => {
            return this.#bolliniMax(value, ctx);
        },
        confirmEmail: (value, ctx) => {
            return this.#confirmEmail(value, ctx);
        },
        buoniMax: (value, ctx) => {
            return this.#buoniMax(value, ctx);
        },
    };

    async init() {
        try {
            this.#validationConfigs = await apiProvider.getValidationConfig();
        } catch (error) {
            console.warn('Could not retrieve validation configs.');
            return;
        }
    }

    /**
     * Validate an input value against a given validation config.
     * @param {*} value The input value to be validated
     * @param {*} validationId The id of the validation config to use for the validation of the value
     * @param {*} ctx The context of the field being validated (object with free properties)
     * @returns The localized error string if the value is invalid, true otherwise
     */
    validate(value, validationId, ctx = null) {
        // check value
        if (!value) {
            console.warn('Missing value to validate. Value deemed valid.');
            return true;
        }

        // check validationId
        if (!validationId) {
            console.warn(`Missing validationId for value ${value}. Value deemed valid.`);
            return true;
        }

        // check presence of a validation config for the given validation id
        const config = this.#validationConfigs.filter((vc) => vc.id === validationId)[0];
        if (!config) {
            console.warn(`Validation config not found for validationId ${validationId}. Value deemed valid.`);
            return true;
        }

        // check presence of a validations array for the given validation config
        const validations = config.validations;
        if (!validations) {
            console.warn(
                `Validations array not found for validation config of id ${validationId}. Value deemed valid.`
            );
            return true;
        }

        // check validations (first to fail returns its error string)
        for (let i = 0; i < validations.length; i++) {
            // current validation
            const v = validations[i];

            // check validity of the validation, skip otherwise
            if (!v.label || !(v.pattern || (v.fn && this.#validationsFn[v.fn]))) {
                console.warn(`Found invalid validation object for the validation config of id ${validationId}. 
                    Missing one among {pattern,fn,label} or the given fn is not implemented. Skipped.`);
                continue;
            }

            // check validation
            if (
                (v.pattern && !value.match(v.pattern instanceof RegExp ? v.pattern : new RegExp(v.pattern))) ||
                (v.fn && !this.#validationsFn[v.fn](value, ctx))
            ) {
                return v.label[document.documentElement.lang || 'en'];
            }

            // validation passed successfully (check the next one)
        }

        // all validations passed successfully
        return true;
    }

    /*******************************************************************************************************************
     * PRIVATE VALIDATION FUNCTIONS
     * Private functions used as helpers by the custom validation functions configured below.
     * Each function must take at least the "value" parameter, optionally the "ctx" parameter and additional args.
     * Make sure that the functions always return true|false.
     *******************************************************************************************************************/

    #pswRipetuta(value, ctx) {
        //console.log(`Called validation function 'pswRipetuta' for value ${value} with context`, ctx);

        // get data from context
        const insertedValue = ctx?.pswObj?.getValue() || null;
        if (!insertedValue) {
            console.warn(`Missing 'pswObj' in context. Could not validate 'pswRipetuta'.`);
            return false;
        }

        return !(value !== null && value !== '' && value !== insertedValue);
    }

    #dataNascita(value, ctx) {
        console.log(`Called validation function 'dataNascita' for value ${value} with context`, ctx);

        // get data from context
        const excludeMinors = ctx?.excludeMinors || false;
        const dateSeparator = ctx?.dateSeparator || '-';

        try {
            if (!this.#dataValidaGiornoMese(value, dateSeparator)) return false;

            if (!this.#dataValidaPassata(value, dateSeparator)) return false;

            if (excludeMinors && !this.#dataValidaMaggiorenni(value, this.#DEFAULT_AGE_LIMIT, dateSeparator)) return false;
        } catch (error) {
            console.warn(`Error in 'dataNascita' validation function: `, error.message);
            return false;
        }
        return true;
    }

    #esisteDataNascitaUtente() {
        console.log(`Called validation function 'esisteDataNascitaUtente'`);

        // get current user birth date
        const userBirthDate = storeManager.get('userData').dataNascita || null;
        if (!userBirthDate) {
            console.warn(`User birth date not found.`);
            return false;
        }

        return true;
    }

    #dataNascitaFiglio(value) {
        console.log(`Called validation function 'dataNascitaFiglio' for value ${value}`);

        // get current user birth date
        const userBirthDate = storeManager.get('userData').dataNascita || null;
        if (!userBirthDate) {
            console.warn(`User birth date not found. Could not validate 'dataNascitaFiglio'.`);
            return false;
        }

        try {
            if (!this.#dataValidaGiornoMese(value)) return false;

            if (!this.#dataValidaPassata(value)) return false;

            // check date <= user birth date
            const valueTime = this.#splitDate(value)?.date?.getTime() || null;
            const userBirthDateTime = this.#splitDate(userBirthDate)?.date?.getTime() || null;
            if (valueTime && userBirthDateTime && valueTime <= userBirthDateTime) return false;
        } catch (error) {
            console.warn(`Error in 'dataNascitaFiglio' validation function: `, error.message);
            return false;
        }

        return true;
    }

    #indirizzoAutocomplete(value, ctx) {
        console.log(`Called validation function 'indirizzo' for value ${value} with context`, ctx);

        // get data from context
        const addressObj = ctx?.addressObj;
        if (!addressObj) {
            console.warn(`Missing 'addressObj' in context. Could not validate 'indirizzo'.`);
            return false;
        }

        return true;
    }

    #indirizzoAutocompleteConVia(value, ctx) {
        console.log(`Called validation function 'indirizzoConVia' for value ${value} with context`, ctx);

        // get data from context
        const addressObj = ctx?.addressObj;
        if (!addressObj) {
            console.warn(`Missing 'addressObj' in context. Could not validate 'indirizzoConVia'.`);
            return false;
        }

        if (!this.#indirizzoAutocompleteValidoVia(addressObj)) return false;

        return true;
    }

    #indirizzoAutocompleteConCivico(value, ctx) {
        console.log(`Called validation function 'indirizzoConCivico' for value ${value} with context`, ctx);

        // get data from context
        const addressObj = ctx?.addressObj;
        if (!addressObj) {
            console.warn(`Missing 'addressObj' in context. Could not validate 'indirizzoConCivico'.`);
            return false;
        }

        if (!this.#indirizzoAutocompleteValidoViaCivico(addressObj)) return false;

        return true;
    }

    #bolliniMax(value, ctx) {
        console.log(`Called validation function 'bolliniMax' for value ${value} with context`, ctx);

        if (parseInt(value) > parseInt(ctx.balance)) {
            return false;
        }
        return true;
    }

    #buoniMax(value, ctx) {
        console.log(`Called validation function 'buoniMax' for value ${value} with context`, ctx);

        if (parseInt(value) > parseInt(ctx.balance)) {
            return false;
        }
        return true;
    }

    #confirmEmail(value, ctx) {
        //console.log(`Called validation function 'confirmEmail' for value ${value} with context`, ctx);

        // get data from context
        const insertedValue = ctx?.emailObj?.getValue() || null;
        if (!insertedValue) {
            console.warn(`Missing 'emailObj' in context. Could not validate 'confirmEmail'.`);
            return false;
        }

        return !(value !== null && value !== '' && value !== insertedValue);
    }

    /*******************************************************************************************************************
     * PRIVATE FUNCTIONS
     *******************************************************************************************************************/

    #dataValidaGiornoMese(value, dateSeparator = '-') {
        try {
            const { dd, mm, yyyy } = this.#splitDate(value, dateSeparator);
            if (mm == 1 || mm > 2) {
                if (dd > this.#MONTH_DAYS[mm - 1]) return false;
            }
            if (mm == 2) {
                let leapYear = (!(yyyy % 4) && yyyy % 100) || !(yyyy % 400);
                if (!leapYear && dd >= this.#FEBRUARY_29) return false;
                if (leapYear && dd > this.#FEBRUARY_29) return false;
            }
            return true;
        } catch (error) {
            console.warn(`Error in 'dataValidaGiornoMese' validation function: `, error.message);
            return false;
        }
    }

    #dataValidaPassata(value, dateSeparator = '-') {
        try {
            const { date } = this.#splitDate(value, dateSeparator);
            return date.getTime() < Date.now();
        } catch (error) {
            console.warn(`Error in 'dataValidaPassata' validation function: `, error.message);
            return false;
        }
    }

    #dataValidaMaggiorenni(value, limit = this.#DEFAULT_AGE_LIMIT, dateSeparator = '-') {
        const ageLimit = !(typeof limit === 'number') || limit <= 0 ? this.#DEFAULT_AGE_LIMIT : limit;
        try {
            const { date } = this.#splitDate(value, dateSeparator);
            return (Date.now() - date.getTime()) / this.#MILLISECONDS_TO_YEARS >= ageLimit;
        } catch (error) {
            console.warn(`Error in 'dataValidaMaggiorenni' validation function: `, error.message);
            return false;
        }
    }

    #indirizzoAutocompleteValidoVia(addressObj) {
        if (!this.#validAddressObj(addressObj)) return false;

        // check street and street number
        if (addressObj[this.#GMAPS_ADDRESS_TYPES].indexOf(this.#GMAPS_ADDRESS_STREET_ADDRESS) == -1) {
            // address without street or street number
            if (addressObj[this.#GMAPS_ADDRESS_TYPES].indexOf(this.#GMAPS_ADDRESS_ROUTE) == -1) {
                // address without street
                return false;
            }
            // address with street but without street number
            return true;
        }

        return true;
    }

    #indirizzoAutocompleteValidoViaCivico(addressObj) {
        if (!this.#validAddressObj(addressObj)) return false;

        // check street and street number
        if (addressObj[this.#GMAPS_ADDRESS_TYPES].indexOf(this.#GMAPS_ADDRESS_STREET_ADDRESS) == -1) {
            // address without street or street number
            if (addressObj[this.#GMAPS_ADDRESS_TYPES].indexOf(this.#GMAPS_ADDRESS_ROUTE) == -1) {
                // address without street
                return false;
            }
            // address with street but without street number
            return false;
        }

        return true;
    }

    #splitDate(value, separator = '-') {
        let split, date, dd, mm, yyyy;
        try {
            split = value.split(separator);
            date = new Date(
                split
                    .map((s) => (s.length == 1 ? `0${s}` : s))
                    .reverse()
                    .join(separator)
            );
            dd = parseInt(split[0], this.#BASE_10);
            mm = parseInt(split[1], this.#BASE_10);
            yyyy = parseInt(split[2], this.#BASE_10);
        } catch (error) {
            throw new Error(`Error while splitting date ${value} with separator ${separator}`);
        }
        return { date, dd, mm, yyyy };
    }

    #validAddressObj(addressObj) {
        if (
            !addressObj ||
            !addressObj[this.#GMAPS_ADDRESS_TYPES] ||
            !addressObj[this.#GMAPS_ADDRESS_ADDRESS_COMPONENTS]
        ) {
            return false;
        }

        return true;
    }

    #addressComp(comps, type, field) {
        const comp = comps.filter((cmp) => cmp.types.includes(type))[0];
        return comp ? comp[field] : '';
    }
}

const defaultFieldValidator = new FieldValidator();
window.ecFieldValidator = defaultFieldValidator;
export const fieldValidator = defaultFieldValidator;
