// eslint-disable-next-line no-unused-vars
import Component from './components/component';

export const MODALITY_SELECTOR = 'selector';
export const MODALITY_DATA_COMPONENT = 'data-component';

export class Register {
    constructor() {
        this.counter = 0;
        this.reg = {};
        this.modality = window.CMP_MODALITY || MODALITY_SELECTOR;
        this.query = '';
        if (this.modality == MODALITY_DATA_COMPONENT) {
            this.query = '[data-component]';
        }
    }

    /**
     * Register a new class to the register
     * @param {String} selector
     * @param {Class} cl
     */
    registerClass(name, cl) {
        let realName = name.startsWith('.') ? name.substring(1) : name;
        this.reg[realName] = cl;

        // recreate query on modality selector
        if (this.modality == MODALITY_SELECTOR) {
            this.query += `${this.query ? ',' : ''}${name}`;
        }
    }

    /**
     * Search for all registered class into the root element and apply the logic
     * @param {HTMLELement} rootEl
     */
    apply(rootEl) {
        const date = new Date();
        this._apply(rootEl);

        const observer = new MutationObserver((mutations) => {
            for (let i = 0; i < mutations.length; ++i) {
                for (let j = 0; j < mutations[i].addedNodes.length; ++j) {
                    this._apply(mutations[i].addedNodes[j]);
                }
            }
        });
        observer.observe(rootEl, {
            childList: true,
            subtree: true,
        });

        const endDate = new Date();
        console.debug(`Register init time: ${endDate.getTime() - date.getTime()}`);
    }

    _apply(el) {
        if (!el.querySelectorAll) return;

        if (el.matches(this.query)) {
            const elFirstClass = el.classList[0];
            if (elFirstClass && this.reg[elFirstClass]) {
                if (!el.matches('[data-lazy-init]')) {
                    this._applyTo(el, elFirstClass, this.reg[elFirstClass]);
                }
            }
        }

        const components = Array.from(el.querySelectorAll(this.query));
        for (const component of components) {
            const firstClass = component.classList[0];
            if (!firstClass || !this.reg[firstClass]) continue;
            if (el.matches('[data-lazy-init]')) continue;

            this._applyTo(component, firstClass, this.reg[firstClass]);
        }
    }

    /**
     * Apply to the specific element the necessary logic
     * @param {HTMLELement} el
     * @returns {}
     */
    applyTo(el) {
        if (el.objReference) return el.objReference;
        if (!el.matches(this.query)) return null;

        const elFirstClass = el.classList[0];
        if (elFirstClass && this.reg[elFirstClass]) {
            return this._applyTo(el, elFirstClass, this.reg[elFirstClass]);
        }
    }

    /**
     * Apply the class to the element
     * @param {HTMLELement} el
     * @param {string} r
     * @param {Class<Component>} component
     * @returns {Component} the component instance
     */
    _applyTo(el, r, componentClazz) {
        if (el.objReference) return el.objReference;
        if (!el.id) el.id = `${r}-${this.counter++}`;
        if (el.objReference) return el.objReference;

        try {
            if (el.objCreating) throw `Recursive or Retentative apply ${r} to element ${el.id}`;
            el.objCreating = true;
            el.objReference = new componentClazz(r, el);
            console.debug(`Created object ${r} to component with id ${el.id}`);
            return el.objReference;
        } catch (e) {
            console.warn(`Cannot create object ${r} to element ${el.id}`, e);
        }
    }

    /**
     * Get class from element
     * @param {HTMLELement} element
     */
    getClass(element) {
        if (!element) return;
        if (element.objReference) return element.objReference;
        const result = this.applyTo(element);
        return result;
    }

    /**
     * Reload for selector
     */
    reload(cmp) {
        let classes = cmp;
        let selector = '.' + cmp;
        if (cmp.startsWith('.')) {
            classes = cmp.substring(1);
            selector = cmp;
        }

        const rootEl = document.documentElement;

        let loader = this.reg[classes];
        if (!loader) {
            console.warn('Cannot reload ' + classes + ' - loader not found');
            return;
        }

        //query
        const elements = rootEl.querySelectorAll(selector);
        for (const el of elements) {
            if (el.objReference) {
                el.objReference.dispose();
                console.info(`Disposed object ${classes} to component with id ${el.id}`);
                el.objReference = null;
            }
            return this._applyTo(el, classes, loader);
        }
    }

    dispose(el) {
        const components = Array.from(el.querySelectorAll(this.query));
        for (const component of components) {
            if (component.objReference) {
                component.objReference.dispose();
            }
        }

        if (el.matches(this.query) && el.objReference) {
            el.objReference.dispose();
        }
    }
}

window.rcRegister = new Register();

/**
 * @returns {Register} the register
 */
export const getRegister = () => {
    return window.rcRegister;
};
export const register = getRegister();
